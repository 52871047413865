import { useEffect, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import Moment from "moment";
import Modal from "react-modal";
import "../../PayingBills/PayingBills.css";

const PersonalTransferStep2 = () => {

  Moment.locale("en");
  Modal.setAppElement("body");

  const [cardList, setCardList] = useState([]);

  const getCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setCardList(x.data);
            console.log("Card List"+x.data);
          }
        });
      }
    });
  };

  useEffect(() => {
    getCardList();
  }, []);


  return (
    <>
      <section class="dashboard-section body-collapse pay">
        <div class="overlay pt-120">
          <div class="container-fruid">
            <div class="main-content">
              <div class="head-area d-flex align-items-center justify-content-between">
                <h4>Transfer To Herman Tran</h4>
                <div class="icon-area">
                  <img src="assets/images/icon/support-icon.png" alt="icon" />
                </div>
              </div>
              <div className="side-items">
                    <div className="single-item">
                      <div className="section-text d-flex align-items-center justify-content-between">
                        <h6>Linked Payment system</h6>
                      </div>

                      
                      <div className="row">
                        {cardList
                          ? cardList.map((card) => (
                              <div
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                                className="cc-card-item linked"
                                key={card.card_token}
                              >
                                <label className="bankname">
                                  {card.card_bank_name}
                                </label>
                                <label className="cardname">
                                  {card.card_name}
                                </label>
                                <label className="cardnumber">
                                  {card.card_bin_first_six.substring(0, 4) +
                                    " " +
                                    card.card_bin_first_six.substring(4, 6) +
                                    "** **** " +
                                    card.card_bin_last_four}
                                </label>
                                <label className="cardexpire">
                                  {card.exp_month} / {card.exp_year}
                                </label>
                              </div>
                            ))
                          : null}
                        
                        <Link to="/personal/add-card"  style={{ marginTop: "8px", marginBottom: "8px" }}
                            className="cc-card-item addnew ">
                         
                            <label className="ml-50">+</label>
                            
                     
                          </Link>
                      
                      </div>
                     
                    </div>
                  </div>

              <form action="#" className="border rounded p-2 mt-3">
                <div class="send-banance">
                  <span class="mdr">
                    <b>You Send</b>
                  </span>
                  <br />
                  <div className="inputArea">
                    <input
                      style={{ border: "none" }}
                      class="xxlr"
                      placeholder="400.00"
                      type="number"
                    />
                  </div>
                  <p>
                    Available Balance<b> $30,700.00</b>
                  </p>
                </div>

                <div class="footer-area mt-40 mb-2">
                  <NavLink to="/personal/transfer">Select another card</NavLink>
                  <a data-bs-toggle="modal" data-bs-target="#transferMod">
                    Transfer
                  </a>
                </div>
              </form>


              <div class="table-responsive mt-5">
                <table class="table border rounded p-2">
                  <thead>
                    <tr>
                      <th scope="col">Name/ Business</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      data-bs-toggle="modal"
                      data-bs-target="#transactionsMod"
                    >
                      <th scope="row">
                        <p>
                          <b>Bangkok Bank</b>
                        </p>
                        <p class="mdr">Withdraw to bank account</p>
                      </th>
                      <td>
                        <p>
                          <b>03:00 PM</b>
                        </p>
                        <p class="mdr">10 Mar 2022</p>
                      </td>
                      <td>
                        <p class="text-warning">
                          <b>In Progress</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>-$520</b>
                        </p>
                        <p class="mdr">$3.0</p>
                      </td>
                    </tr>
                    <tr
                      data-bs-toggle="modal"
                      data-bs-target="#transactionsMod"
                    >
                      <th scope="row">
                        <p>
                          <b>Envato Pty Ltd</b>
                        </p>
                        <p class="mdr">Marketplace Payment Received</p>
                      </th>
                      <td>
                        <p>
                          <b>04:30 PM</b>
                        </p>
                        <p class="mdr">01 Mar 2022</p>
                      </td>
                      <td>
                        <p class="text-success">
                          <b>Completed</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>+$450</b>
                        </p>
                        <p class="mdr">No Fees</p>
                      </td>
                    </tr>

                    <tr
                      data-bs-toggle="modal"
                      data-bs-target="#transactionsMod"
                    >
                      <th scope="row">
                        <p>
                          <b>Envato Pty Ltd</b>
                        </p>
                        <p class="mdr">Marketplace Payment Received</p>
                      </th>
                      <td>
                        <p>
                          <b>04:30 PM</b>
                        </p>
                        <p class="mdr">01 Mar 2022</p>
                      </td>
                      <td>
                        <p class="text-danger">
                          <b>Cancelled</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>+$450</b>
                        </p>
                        <p class="mdr">No Fees</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              {/*  Transfer Popup start  */}
              <div class="transfer-popup">
                <div class="container-fruid">
                  <div class="row">
                    <div class="col-lg-16">
                      <div
                        class="modal fade"
                        id="transferMod"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="tab-content  w-100 p-3">
                            <div
                              class="tab-pane fade show active"
                              id="transfer"
                              role="tabpanel"
                              aria-labelledby="transfer-tab "
                            >
                              <div class="modal-content ">
                                <div class="modal-header  justify-content-between">
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>

                                <div class="main-content">
                                  <div className="paymentDetails">
                                    <div class="row">
                                      <div class="col-xl-12">
                                        <ul>
                                          <li>
                                            <span>You Send : </span>
                                            <b> 400.00 GBP</b>
                                          </li>
                                          <li>
                                            <span>Recipient gets : </span>
                                            <b> 400.00 GBP</b>
                                          </li>
                                          <li>
                                            <span>E-mail of receiver : </span>
                                            <b> morris22@gmail.com</b>
                                          </li>
                                          <li>
                                            <span>Fee : </span>
                                            <b> Free</b>
                                          </li>
                                          <li>
                                            <span>Purpose : </span>
                                            <b> Software development</b>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="main-content border rounded mt-2">
                                    <h4>Confirm Transfer!</h4>
                                    <p>
                                      We have sent a verification code on your
                                      phone + Number +44831***932. Please enter
                                      verification code below
                                    </p>
                                    <form action="javascript:void(0)">
                                      <div class="userInput">
                                        <input type="text" />
                                        <input type="text" />
                                        <input type="text" />
                                        <input type="text" />
                                      </div>
                                      <a href="javascript:void(0)">
                                        Don’t receive a code?
                                      </a>
                                      <br />
                                    
                                      <div className="col-12">
                                      <div className="btn-border w-100">
                                        <button className="cmn-btn w-100">
                                          Confirm
                                        </button>
                                      </div>
                                    </div>

                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  Transfer Popup start  */}
            </div>
          </div>
        </div>
      </section>

      <div className="transactions-popup mycard">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="modal fade"
                id="transactionsMod"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-between">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <div className="main-content">
                      <div className="row">
                        <div className="col-sm-4">
                          <h5>My Cards</h5>
                          <div className="icon-area">
                            <img
                              src="assets/images/popup-card.png"
                              alt="image"
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <div className="right-area">
                            <div className="top-area d-flex align-items-center justify-content-between">
                              <div className="card-details d-flex align-items-center">
                                <img
                                  src="assets/images/icon/mastercard.png"
                                  alt="image"
                                />
                                <span>5880 **** **** 8854</span>
                              </div>
                            </div>
                            <ul className="payment-details">
                              <li>
                                <span>Card Type:</span>
                                <span>Visa</span>
                              </li>
                              <li>
                                <span>Card Holder:</span>
                                <span>Alfred Davis</span>
                              </li>
                              <li>
                                <span>Expires:</span>
                                <span>12/19</span>
                              </li>
                              <li>
                                <span>Card Number:</span>
                                <span>5880 5087 3288 8854</span>
                              </li>
                              <li>
                                <span>Total Balance:</span>
                                <span>80,700.00</span>
                              </li>
                              <li>
                                <span>Total Debt:</span>
                                <span>8,250.00</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalTransferStep2;
