import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../context/AuthProvider";

const Logout = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };
        fetch('api/auth/logout', requestOptions)
            .then(response => {
            })
            .finally(() => {
                setAuth({});
                navigate('/auth/login');
            })
    }, []);

    return (
        <></>
        );
}

export default Logout;