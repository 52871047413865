import React, { Component, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import HttpInterceptor from './context/HttpInterceptor';
import Layout from './components/Shared/Layout';
import AuthLayout from './components/Auth/AuthLayout';
import Home from './components/Home';
import Counter from './components/Counter';
import Login from './components/Auth/Login'
import Logout from './components/Auth/Logout'
import OTP from './components/Auth/OTP'
import Password from './components/Auth/Password'
import CheckUser from './components/Auth/CheckUser'
import Register from './components/Auth/Register'
import RequireAuth from './components/Auth/RequireAuth'
import AuthContext from "./context/AuthProvider";
import LangContext from "./context/LangProvider";


import PersonalLayout from './components/Personal/PersonalLayout';
import PersonalDashboard from './components/Personal/PersonalDashboard';
import PersonalTransactions from './components/Personal/PersonalTransactions';
import PersonalTransferStep1 from './components/Personal/Transfer/PersonalTransferStep1';
import PersonalDeposit from './components/Personal/PersonalDeposit';
import PersonalWithdraw from './components/Personal/PersonalWithdraw';
import PersonalAccount from './components/Personal/PersonalAccount';



import BusinessLayout from './components/Business/BusinessLayout';
import BusinessDashboard from './components/Business/BusinessDashboard';
import BusinessDeposit from './components/Business/BusinessDeposit';
import BusinessTransfer from './components/Business/BusinessTransfer';
import BusinessTransactions from './components/Business/BusinessTransactions';
import BusinessWithdraw from './components/Business/BusinessWithdraw';

import './custom.css'


import PayingBills from './components/PayingBills/PayingBills';
import MobileOperators from './components/PayingBills/MobileOperators';
import Favorites from './components/PayingBills/Favorite';
import TransferCards from './components/PayingBills/TransferCards';
import Reports from './components/PayingBills/Reports';
import PersonalTransferStep2 from './components/Personal/Transfer/PersonalTransferStep2';
import AddCard from './components/Personal/AddCard';
import TransferPersonal from './components/Personal/Transfer/TransferPersonal';
import ResetPassword from './components/Auth/ResetPassword';

const App = () => {
    const { auth } = useContext(AuthContext);
    const { lang } = useContext(LangContext);
    HttpInterceptor();

    return (
        <Routes>
            <Route path="/" element={<AuthLayout />}>
                <Route exact path='/' element={<CheckUser />} />
                <Route path='/auth/login' element={<Login />} />
                <Route path='/auth/otp' element={<OTP />} />
                <Route path='/auth/reset/password' element={<ResetPassword />} />
                
                <Route path='/auth/password' element={<Password />} />
                <Route path="/auth/unauthorized" element={<Login />} />
                <Route path="/auth/register" element={<Register />} />
                <Route path="/auth/logout" element={<Logout />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={["PERSONAL"]} />}>
                <Route path="/" element={<PersonalLayout />}>
                    <Route path='/personal' element={<PersonalDashboard />} />
                    <Route path='/personal/transactions' element={<PersonalTransactions />} />
                    <Route path='/personal/transfer' element={<TransferPersonal />} />
                    <Route path='/personal/deposit' element={<PersonalDeposit />} />
                    <Route path='/personal/withdraw' element={<PersonalWithdraw />} />
                    <Route path='/personal/account' element={<PersonalAccount />} />
                    
                    {/* pay design/ */}
                    <Route path='/personal/payying-bills' element={<PayingBills />} /> 
                    <Route path='/personal/mobile-operators' element={<MobileOperators />} /> 
                    <Route path='/personal/favorites' element={<Favorites />} /> 
                    <Route path='/personal/transfer-card' element={<TransferCards />} /> 
                    <Route path='/personal/reports' element={<Reports />} /> 
                    <Route path='/personal/transfer/step-2' element={<PersonalTransferStep2 />} /> 
                    <Route path='/personal/add-card' element={<AddCard />} />
                     

                      {/* pay design\ */}
                </Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={["BUSINESS"]} />}>
                <Route path="/" element={<BusinessLayout />}>
                    <Route path='/business' element={<BusinessDashboard />} />
                    <Route path='/business/deposit' element={<BusinessDeposit />} />
                    <Route path='/business/transfer' element={<BusinessTransfer />} />
                    <Route path='/business/transactions' element={<BusinessTransactions />} />
                    <Route path='/business/withdraw' element={<BusinessWithdraw />} />
                </Route>
            </Route>
            <Route exact path='*' element={<CheckUser />} />
            {/*<Route path="/auth" element={<AuthLayout />}>*/}
            {/*</Route>*/}
        </Routes>
    );
}

export default App;