import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

const Password = () => {

    const passwordRef = useRef();
    const errRef = useRef();

    const [passwordkey, setPasswordkey] = useState('');
    const [loading, setLoading] = useState(false);
    const [trustdevice, setTrustdevice] = useState(true);
    const [deviceInfo, deviceInfoSet] = useState({});

    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('deviceinfo'));
        if (items) {
            deviceInfoSet(items);
        }
        passwordRef.current.focus();
    }, []);

    useEffect(() => {
        localStorage.setItem('deviceinfo', JSON.stringify(deviceInfo));
    }, [deviceInfo]);

    useEffect(() => {
        setErrMsg('');
    }, [passwordkey]);

    const confirmSubmit = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password: passwordkey, trustdevice, deviceId: deviceInfo.deviceId ?? "", deviceToken: deviceInfo.deviceToken ?? "" })
        };
        setLoading(true);
        setPasswordkey('');
        fetch('api/auth/password', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            if (trustdevice && x.data) {
                                deviceInfoSet({ deviceId: x.data.device_id, deviceToken: x.data.device_token });
                            }
                            navigate("/");
                        } else {
                            setErrMsg(x.message);
                        }
                    });
                } else {
                    setErrMsg("Server error");
                }
            })
            .finally(setLoading(false))
    }

    return (
        <>
            {loading ?
                <div className="preloader"></div> :
                null
            }
            <section className="log-reg login-2">
                <div className="overlay">
                    <div className="container">
                        <div className="top-head-area">
                            <div className="row d-flex align-items-center">
                                <div className="col-sm-5 col">
                                    <a className="back-home" href="index.html">
                                        <img src="auth-assets/img/left-icon.png" alt="image" />
                                        Back To Parapay
                                    </a>
                                </div>
                                <div className="col-sm-5 col">
                                    <a href="https://www.parapay.uz">
                                        <img style={{width:"250px"}} src="auth-assets/img/parapay/Parapay_blue_bg.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <div className="img-area">
                                    <img src="auth-assets/img/login-bg-2.png" alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120">
                                <div className="form-box" style={{ width: '100%' }}>
                                    <h4>Enter your password</h4>
                                    <p ref={errRef} className={errMsg ? "alert alert-danger" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab">
                                            <form onSubmit={confirmSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="password"
                                                                placeholder="Password"
                                                                ref={passwordRef}
                                                                autoComplete="off"
                                                                onChange={(e) => setPasswordkey(e.target.value)}
                                                                value={passwordkey}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="remember-forgot d-flex justify-content-between">
                                                    <div className="form-group d-flex">
                                                        <div className="checkbox_wrapper">
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => setTrustdevice(e.target.checked)}
                                                                defaultChecked={trustdevice}
                                                                className="check-box"
                                                                id="check1"
                                                            />
                                                            <label />
                                                        </div>
                                                        <label htmlFor="check1"><span className="check_span">Trust this device?</span></label>
                                                    </div>
                                                    <div className="forget-pw">
                                                        <Link to="/auth/reset/password">Forgot your password?</Link>
                                                    </div>
                                                </div>
                                                <div className="btn-area">
                                                    <button className="cmn-btn">Confirm</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Password;