import { createContext, useState } from "react";
import { useCookies } from 'react-cookie';

const LangContext = createContext("");

export const LangProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['lang']);
    const [lang, setLang] = useState(cookies);

    return (
        <LangContext.Provider value={{ lang, setLang }}>
            {children}
        </LangContext.Provider>
    )
}

export default LangContext;