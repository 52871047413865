import { useEffect, useRef, useState } from 'react';
import Moment from 'moment'
import CurrencyFormat from 'react-currency-format';
import useAuth from '../../hooks/useAuth'
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const BusinessTransfer = () => {
    Moment.locale('en');
    Modal.setAppElement('body');

    const defaultCommission = {
        fixedAmount: 0.0,
        rateAmount: 0.0,
        totalAmount: 0.0,
        feeType: 1,
        receiverFixedAmount: 0.0,
        receiverRateAmount: 0.0,
        receiverTotalAmount: 0.0
    }

    const navigate = useNavigate();
    const [errorIsOpen, setErrorIsOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");


    const errorModalStyles = {
        overlay: {
            backgroundColor: '#00000070'
        },
    };

    const toggleErrorModal = (msg) => {
        setErrorMessage(msg);
        setErrorIsOpen(!errorIsOpen);
    }

    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [recipientList, setRecipientList] = useState([]);
    const [transferStep, setTransferStep] = useState(1);
    const [selectedRecipient, setSelectedRecipient] = useState({});
    const { auth } = useAuth();
    const [masterBalance, setMasterBalance] = useState(0.0);
    const [currency] = useState("UZS");
    const [transferAmount, setTransferAmount] = useState(0.0);
    const [commission, setCommission] = useState(defaultCommission);
    const [transferDescription, setTransferDescription] = useState("");

    const getRecipientList = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text: searchText })
        };
        setLoading(true);
        fetch('api/recipient/search', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            setRecipientList(x.data);
                        }
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const chooseRecipient = (recipient) => {
        setSelectedRecipient(recipient);
        setTransferStep(2);
        setSearchText("");
        setRecipientList([]);
    }

    const changeRecipient = () => {
        setTransferStep(1);
        setSelectedRecipient({});
        setTransferAmount(0.0);
        setCommission(defaultCommission);
    }

    const changeTransferAmount = () => {
        setTransferStep(2);
        setTransferAmount(0.0);
        setCommission(defaultCommission);
    }

    const gotoStep3 = () => {
        setTransferStep(3);
    }

    const confirmTransfer = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ isbusiness: selectedRecipient.business_flag, amount: transferAmount, currency, receiver_account_number: selectedRecipient.account_number, description: transferDescription })
        };

        setLoading(true);
        try {
            var response = await fetch('api/transaction/transfer', requestOptions);
            if (response.ok) {
                response.json().then(x => {
                    if (x.status) {
                        setModalIsError(false);
                        toggleErrorModal("Transfer was successful");
                        setTimeout(() => {
                            navigate("/personal");
                        }, 1000)
                    } else {
                        setModalIsError(true);
                        toggleErrorModal(x.message)
                    }
                });
            } else {
                const data = (await response.body.getReader().read()).value
                const str = String.fromCharCode.apply(String, data);
                setModalIsError(true);
                toggleErrorModal(JSON.parse(str).message)
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchText.length > 2) {
                getRecipientList();
            }
        }, 600)

        return () => clearTimeout(delayDebounceFn)
    }, [searchText]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ isbusiness: selectedRecipient.business_flag, amount: transferAmount, currency })
            };

            fetch('api/transaction/calculatetransfercommission', requestOptions)
                .then(response => {
                    if (response.ok) {
                        response.json().then(x => {
                            if (x.status) {
                                setCommission(x.data);
                            }
                        });
                    }
                })
        }, 600)

        return () => clearTimeout(delayDebounceFn)
    }, [transferAmount]);

    useEffect(() => {
        setMasterBalance(auth.user.wallets.filter(x => x.currency == currency).reduce((a, v) => a = a + v.cash_available_balance + v.credit_available_balance, 0));
    }, []);

    return (
        <>
            {loading ?
                <div className="preloader"></div> :
                null
            }
            <section className={(transferStep > 1 ? "step-2" : "") + (transferStep > 2 ? " step-3 " : "") + " dashboard-section body-collapse pay step"}>
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="main-content">
                            <div className="head-area d-flex align-items-center justify-content-between">
                                <h4>Make a Transfer</h4>
                                <div className="icon-area">
                                    <img src="assets/images/icon/support-icon.png" alt="icon" />
                                </div>
                            </div>
                            {
                                transferStep == 1 ?
                                    <>
                                        <div className="choose-recipient">
                                            <div className="step-area">
                                                <span className="mdr">Step 1 of 3</span>
                                                <h5>Search Recipient</h5>
                                            </div>
                                        </div>
                                        <div className="flex-fill form">
                                            <div className="form-group d-flex align-items-center">
                                                <img src="assets/images/icon/search.png" alt="icon" />
                                                <input
                                                    value={searchText}
                                                    onChange={((e) => setSearchText(e.target.value))}
                                                    type="text"
                                                    placeholder="Enter phone number or account number" />
                                            </div>
                                        </div>
                                        <div className="user-select">
                                            {
                                                recipientList.map((recipient) => (
                                                    <div className="single-user" key={recipient.account_number}>
                                                        <div className="left d-flex align-items-center">
                                                            {/*<div className="img-area">*/}
                                                            {/*    <img src="assets/images/profile-1.png" alt="image" />*/}
                                                            {/*</div>*/}

                                                            <div className="text-area">
                                                                {
                                                                    recipient.business_flag ?
                                                                        <p>{recipient.company_name}</p>
                                                                        :
                                                                        <p>{recipient.name} {recipient.surname} </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            <a onClick={(() => chooseRecipient(recipient))}>Choose</a>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                recipientList.length == 0 && searchText != "" ?
                                                    <p>No recipient found</p>
                                                    :
                                                    recipientList.length == 0 ?
                                                        <p>Let's search</p> : null
                                            }
                                        </div>
                                    </> : transferStep == 2 ?
                                        <>
                                            <div className="choose-recipient">
                                                <div className="step-area">
                                                    <span className="mdr">Step 2 of 3</span>
                                                    <h5>Set Amount of transfer</h5>
                                                </div>
                                                <div className="user-select">
                                                    <div className="single-user">
                                                        <div className="left d-flex align-items-center">
                                                            {/*<div className="img-area">*/}
                                                            {/*    <img src="assets/images/profile-1.png" alt="image" />*/}
                                                            {/*</div>*/}
                                                            <div className="text-area">
                                                                {
                                                                    selectedRecipient.business_flag ?
                                                                        <p>{selectedRecipient.company_name}</p>
                                                                        :
                                                                        <p>{selectedRecipient.name} {selectedRecipient.surname} </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            <a onClick={(() => changeRecipient())}>
                                                                <i className="icon-h-edit" />
                                                                Edit
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form action="#">
                                                <div className="send-banance">
                                                    <span className="mdr">You Send</span>
                                                    <div className="input-area">
                                                        <CurrencyFormat
                                                            placeholder="UZS1,000"
                                                            className="xxlr"
                                                            thousandSeparator={true}
                                                            prefix="UZS"
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            onValueChange={(values) => {
                                                                setTransferAmount(values.floatValue)
                                                            }}
                                                            isAllowed={
                                                                ((inpVal) =>
                                                                    inpVal.floatValue ? inpVal.floatValue <= masterBalance : true
                                                                )
                                                            }
                                                        />
                                                        {/*<select>*/}
                                                        {/*    <option value={1}>USD</option>*/}
                                                        {/*    <option value={2}>USD</option>*/}
                                                        {/*    <option value={3}>USD</option>*/}
                                                        {/*</select>*/}
                                                    </div>
                                                    <p>Available Balance
                                                        <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={masterBalance} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <b>{value}</b>} />
                                                    </p>
                                                </div>
                                                <ul className="total-fees">
                                                    <li>Total Fees</li>
                                                    {
                                                        commission.totalAmount == 0 ?
                                                            <li>Free</li> :
                                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} decimalScale={2} value={commission.totalAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <li>{value}</li>} />
                                                    }
                                                </ul>
                                                <ul className="total-fees pay">
                                                    <li><h5>Total To Pay</h5></li>
                                                    <li><CurrencyFormat decimalScale={2} fixedDecimalScale={true} decimalScale={2} value={transferAmount + commission.totalAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <h5>{value}</h5>} /></li>
                                                </ul>
                                                <div className="footer-area mt-40">
                                                    <a onClick={(() => changeRecipient())}>Previous Step</a>
                                                    <a onClick={(() => gotoStep3())} className="active">Next</a>
                                                </div>
                                            </form>
                                        </> : transferStep == 3 ?
                                            <>
                                                <div className="choose-recipient">
                                                    <div className="step-area">
                                                        <span className="mdr">Step 3 of 3</span>
                                                        <h5>Confirm Your Transfer</h5>
                                                    </div>
                                                    <div className="user-select">
                                                        <div className="single-user">
                                                            <div className="left d-flex align-items-center">
                                                                <div className="text-area">
                                                                    {
                                                                        selectedRecipient.business_flag ?
                                                                            <p>{selectedRecipient.company_name}</p>
                                                                            :
                                                                            <p>{selectedRecipient.name} {selectedRecipient.surname} </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="right">
                                                                <a onClick={(() => changeRecipient())}>
                                                                    <i className="icon-h-edit" />
                                                                    Edit
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="payment-details">
                                                    <div className="top-area">
                                                        <h6>Payment Details</h6>
                                                        <div className="right">
                                                            <a onClick={(() => changeTransferAmount())}>
                                                                <i className="icon-h-edit" />
                                                                Edit
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-6">
                                                            <ul className="details-list">
                                                                <li>
                                                                    <span>You Send</span>
                                                                    <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transferAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <b>{value}</b>} />
                                                                </li>
                                                                {
                                                                    commission.receiverTotalAmount > 0 ?
                                                                        <li>
                                                                            <span>Recipient fee</span>
                                                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={commission.receiverTotalAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <b>{value}</b>} />
                                                                        </li> : null
                                                                }
                                                                <li>
                                                                    <span>Recipient gets</span>
                                                                    <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transferAmount - commission.receiverTotalAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <b>{value}</b>} />
                                                                </li>
                                                                <li>
                                                                    <span>Account number of receiver</span>
                                                                    <b>{selectedRecipient.account_number}</b>
                                                                </li>
                                                                <li>
                                                                    <span>Fee</span>
                                                                    {
                                                                        commission.totalAmount == 0 ?
                                                                            <b>Free</b> :
                                                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={commission.totalAmount} displayType={'text'} thousandSeparator={true} prefix="UZS" renderText={value => <b>{value}</b>} />
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <span>Description</span>
                                                                    <input type="text"
                                                                        value={transferDescription}
                                                                        onChange={((e) => setTransferDescription(e.target.value))}
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <span>Transfer will be receive on </span>
                                                                    <b>{Moment().format('DD MMM yyyy HH:mm')} </b>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form">
                                                    <div className="checkbox">
                                                        <input type="checkbox" id="confirm" />
                                                        <label htmlFor="confirm">I confirm the payment details above</label>
                                                    </div>
                                                    <div className="footer-area mt-40">
                                                        <a onClick={(() => changeTransferAmount())}>Previous Step</a>
                                                        <a onClick={(() => confirmTransfer())} className="transferMod active">Pay</a>
                                                    </div>
                                                </div>
                                            </> :
                                            null
                            }
                        </div>
                    </div>
                </div>
            </section>

            <CSSTransition
                in={errorIsOpen}
                timeout={300}
                classNames="dialog"
            >
                <Modal
                    closeTimeoutMS={500}
                    isOpen={errorIsOpen}
                    style={errorModalStyles}
                    className={(modalIsError ? "error-modal" : "success-modal") + " modal-dialog modal-dialog-centered transfer-popup"}
                    onClick={(() => toggleErrorModal(""))}
                >
                    <div className="modal-content">
                        <div className="modal-header mb-60 justify-content-between">
                            <button type="button" onClick={(() => toggleErrorModal(""))} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="main-content text-center">
                            {
                                modalIsError ?
                                    <>
                                        <img src="assets/images/icon/error.png" alt="icon" />
                                        <h3>Error</h3>
                                    </>
                                    :
                                    <>
                                        <img src="assets/images/icon/success.png" alt="icon" />
                                        <h3>Success</h3>
                                    </>
                            }

                            <p>{errorMessage}</p>
                        </div>
                    </div>
                </Modal>
            </CSSTransition>
        </>
    )
}

export default BusinessTransfer;