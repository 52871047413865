import React, { useRef, useState, createRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Moment from "moment";
import Modal from "react-modal";
import "./PayingBills.css";
import { Arrow } from "./Transfer/Arrow.tsx";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import {Card, CardParapay} from "../Personal/Card";

const TransferCards = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  const { auth } = useAuth();
  const [senderCardList, setSenderCardList] = useState([]);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });

  const solCardRef = useRef(0);
  const sagCardRef = useRef(0);

  const solCardsRefs = useRef([]);
  const sagCardsRefs = useRef([]);

  const getSolPosition = (e) => {
    let solCard = solCardsRefs.current[e];
    if (solCard) {
      solCardRef.current = e;
      const x = solCard.offsetLeft + solCard.offsetWidth;
      const y = solCard.offsetTop + solCard.offsetHeight / 2;
      setStartPoint({ x: x, y: y });
    }
  };

  const getSagPosition = (e) => {
    let sagCard = sagCardsRefs.current[e];
    if (sagCard) {
      sagCardRef.current = e;
      const x = sagCard.offsetLeft;
      const y = sagCard.offsetTop + sagCard.offsetHeight / 2;
      setEndPoint({ x: x, y: y });
    }
  };

  useEffect(() => {
    getSenderCardList();
    window.addEventListener("resize", updatePosition);
  }, []);

  useEffect(() => {
    if (senderCardList) {
      getSolPosition(0);
      getSagPosition(0);
    }
  }, [senderCardList]);

  const updatePosition = () => {
    getSolPosition(solCardRef.current);
    getSagPosition(sagCardRef.current);
  };

  const getSenderCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setSenderCardList(x.data);
            localStorage.setItem("senderCards", JSON.stringify(x.data));
          }
        });
      }
    });
  };

  useEffect(() => {
    const senderCards = JSON.parse(localStorage.getItem("senderCards"));
    if (senderCards) {
      setSenderCardList(senderCards);
    }
  }, []);

  const defaultCommission = {
    fixedAmount: 0.0,
    rateAmount: 0.0,
    totalAmount: 0.0,
    feeType: 1,
    receiverFixedAmount: 0.0,
    receiverRateAmount: 0.0,
    receiverTotalAmount: 0.0,
  };

  const [transferAmount, setTransferAmount] = useState(0.0);
  const [commission, setCommission] = useState(defaultCommission);
  const [masterBalance, setMasterBalance] = useState(0.0);
  const [currency] = useState("UZS");
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [transferDescription, setTransferDescription] = useState("");
  const [walletBalance, setWalletBalance] = useState(0.0);
  const [loading, setLoading] = useState(false);

  const [fromCard, setFromCard] = useState({});
  const [transferCard, setTransferCard] = useState({});

  const navigate = useNavigate();
  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [modalIsError, setModalIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleErrorModal = (msg) => {
    setErrorMessage(msg);
    setErrorIsOpen(!errorIsOpen);
  };

  const confirmTransfer = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        description: transferDescription,
        senderCard: fromCard.card_token,
        recipientId: selectedRecipient.recipient_id,
        amount: transferAmount,
        currency: currency,
      }),
    };

    setLoading(true);
    try {
      var response = await fetch("api/transaction/transfer", requestOptions);
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setModalIsError(false);
            toggleErrorModal("Transfer was successful");
            setTimeout(() => {
              navigate("/personal");
            }, 1000);
          } else {
            setModalIsError(true);
            toggleErrorModal(x.message);
          }
        });
      } else {
        const data = (await response.body.getReader().read()).value;
        const str = String.fromCharCode.apply(String, data);
        setModalIsError(true);
        toggleErrorModal(JSON.parse(str).message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      Object.keys(selectedRecipient).length > 0 &&
      transferAmount > 0 &&
      Object.keys(fromCard).length > 0
    ) {
      const delayDebounceFn = setTimeout(() => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            senderCard: fromCard.card_token,
            recipientId: selectedRecipient.recipient_id,
            amount: transferAmount,
            currency,
          }),
        };

        try {
          setLoading(true);
          fetch(
            "api/transaction/calculatetransfercommission",
            requestOptions
          ).then((response) => {
            if (response.ok) {
              response.json().then((x) => {
                if (x.status) {
                  setCommission(x.data);
                }
              });
            }
          });
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }, 600);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [transferAmount, selectedRecipient, fromCard]);

  useEffect(() => {
    setMasterBalance(fromCard.balance);
  }, [fromCard]);

  useEffect(() => {
    setWalletBalance(
      auth.user.wallets
        .filter((x) => x.currency == currency)
        .reduce(
          (a, v) =>
            (a = a + v.cash_available_balance + v.credit_available_balance),
          0
        )
    );
  }, []);

  useEffect(() => {
    getRecipient(transferCard);
  }, [transferCard]);

  const getRecipient = async (recipientCard) => {
    if (!recipientCard) return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ text: recipientCard.card_token }),
    };

    fetch("api/recipient/searchown", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setSelectedRecipient(x.data);
          }
        });
      }
    });
  };

  return (
    <>
      {loading ? <div className="preloader"></div> : null}
      <Arrow
        startPoint={startPoint}
        endPoint={endPoint}
        config={{
          arrowColor: "red",
          strokeWidth: 1,
          controlPointsColor: "#aaa",
        }}
        showDebugGuideLines
      />
      <section class="dashboard-section body-collapse pay">
        <div class="overlay pt-120">
          <div class="container-fruid">
            <div class="main-content">
              <div class="head-area d-flex align-items-center justify-content-between">
                <h4>Transfers</h4>
                <div className="icon-area border rounded border-info p-0">
                  <NavLink to="/personal/transfer">
                    <button
                      className="rounded border btn btn-outline-info btn-sm  "
                      style={{ border: "none !import" }}
                    >
                      Transfer of funds
                    </button>
                  </NavLink>
                  <NavLink to="/personal/transfer-card">
                    <button
                      className="rounded border btn btn-outline-info btn-sm active"
                      style={{ border: "none !import" }}
                    >
                      Between my cards
                    </button>
                  </NavLink>
                </div>
              </div>
              <div class="row pb-120">
                <div className="col-2">&nbsp;&nbsp;From Card</div>
                <div className="col"></div>
                <div className="col">Transfer To Card</div>

                <div class="container">
                  <div class="row">
                    <div class="col-2">
                      <button
                        key="0"
                        ref={(ref) => {
                          solCardsRefs.current[1] = ref;
                        }}
                        onClick={() => {
                          setFromCard({
                            card_token: currency,
                            balance: walletBalance,
                          });
                          getSolPosition(1);
                        }}
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          backgroundSize: "cover",
                        }}
                        className="cc-card-item parapay"
                      >
                         <CardParapay cardNumber={auth.user.account.account_number} WalletBalance={walletBalance}/>
                      </button>
                      {senderCardList.map((card, index) => (
                      
                        <div
                        style={{
                                 marginTop: "8px",
                                 marginBottom: "8px",
                                 backgroundSize: "cover",
                               }}
                               className="cc-card-item parapay"
                               key={card.card_token}
                               ref={(ref) => {
                                 solCardsRefs.current[index + 2] = ref;
                               }}
                               onClick={() => {
                                 setFromCard(card);
                                 getSolPosition(index + 2);
                               }}
                      >
                   <Card
                     ref={(ref) => {
                       sagCardsRefs.current[index + 2] = ref;
                     }}
                     onClick={() => {
                       setTransferCard(card);
                       getSagPosition(index + 2);
                     }}
                     cardLists={senderCardList}
                   />
                   </div>
                      ))}   
                    </div> <div style={{ width: "450px" }} />


                    <div class="col">
                      <button
                        key="0"
                        ref={(ref) => {
                          sagCardsRefs.current[1] = ref;
                        }}
                        onClick={() => {
                          setTransferCard({
                            card_token: currency,
                            balance: walletBalance,
                          });
                          getSagPosition(1);
                        }}
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          backgroundSize: "cover",
                        }}
                        className="cc-card-item parapay"
                      >
                        <CardParapay cardNumber={auth.user.account.account_number} WalletBalance={walletBalance}/>
                      </button>

                      {/*RECEIVER CARDS START*/}
                      {senderCardList.map((card, index) => (
                         
                          <div
                               style={{
                                 marginTop: "8px",
                                 marginBottom: "8px",
                                 backgroundSize: "cover",
                               }}
                               className="cc-card-item parapay"
                               key={card.card_token}
                               ref={(ref) => {
                                 sagCardsRefs.current[index + 2] = ref;
                               }}
                               onClick={() => {
                                 setTransferCard(card);
                                 getSagPosition(index + 2);
                               }}
                             >
                          <Card
                            ref={(ref) => {
                              sagCardsRefs.current[index + 2] = ref;
                            }}
                            onClick={() => {
                              setTransferCard(card);
                              getSagPosition(index + 2);
                            }}
                            cardLists={senderCardList}
                          />
                          </div>
                      ))}
                    </div>
                    {/*RECEIVER CARDS END*/}
                    <form className="border rounded p-2 mt-3">
                      <div class="send-banance">
                        <span class="mdr">
                          <b>You Send</b>
                        </span>
                        <br />
                        <span>
                          Amount with commission :{" "}
                          <CurrencyFormat
                            value={transferAmount + commission.totalAmount}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency}
                            renderText={(value) => <b>{value}</b>}
                          />
                        </span>
                        <br />
                        <div className="mt-3 mb-3" style={{ border: "none" }}>
                          <CurrencyFormat
                            placeholder="UZS1,000"
                            className="xxlr"
                            thousandSeparator={true}
                            prefix="UZS"
                            allowNegative={false}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            onValueChange={(values) => {
                              setTransferAmount(values.floatValue);
                            }}
                            isAllowed={(inpVal) =>
                              inpVal.floatValue
                                ? inpVal.floatValue <= masterBalance
                                : true
                            }
                          />
                        </div>
                        <p>
                          Available Balance:{" "}
                          <CurrencyFormat
                            value={masterBalance}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency}
                            renderText={(value) => <b>{value}</b>}
                          />
                        </p>
                      </div>

                      <div class="footer-area mt-40 mb-2">
                        <a data-bs-toggle="modal" data-bs-target="#transferMod">
                          Transfer
                        </a>
                      </div>
                    </form>

                    {/*  Transfer Popup start  */}
                    <div class="transfer-popup">
                      <div class="container-fruid">
                        <div class="row">
                          <div class="col-lg-16">
                            <div
                              class="modal fade"
                              id="transferMod"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="tab-content  w-100 p-3">
                                  <div
                                    class="tab-pane fade show active"
                                    id="transfer"
                                    role="tabpanel"
                                    aria-labelledby="transfer-tab "
                                  >
                                    <div class="modal-content ">
                                      <div class="modal-header  justify-content-between">
                                        <button
                                          type="button"
                                          class="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>

                                      <div class="main-content">
                                        <div className="paymentDetails">
                                          <div class="row">
                                            <div class="col-xl-12">
                                              <ul>
                                                <li>
                                                  <span>You Send: </span>{" "}
                                                  <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={transferAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix="UZS"
                                                    renderText={(value) => (
                                                      <b>{value}</b>
                                                    )}
                                                  />
                                                </li>
                                                <li>
                                                  <span>Fee: </span>{" "}
                                                  {commission.totalAmount ==
                                                  0 ? (
                                                    <b>Free</b>
                                                  ) : (
                                                    <CurrencyFormat
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      value={
                                                        commission.totalAmount
                                                      }
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix="UZS"
                                                      renderText={(value) => (
                                                        <b>{value}</b>
                                                      )}
                                                    />
                                                  )}
                                                </li>
                                                {commission.receiverTotalAmount >
                                                0 ? (
                                                  <li>
                                                    <span>Recipient fee: </span>{" "}
                                                    <CurrencyFormat
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      value={
                                                        commission.receiverTotalAmount
                                                      }
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix="UZS"
                                                      renderText={(value) => (
                                                        <b>{value}</b>
                                                      )}
                                                    />
                                                  </li>
                                                ) : null}
                                                <li>
                                                  <span>Recipient gets: </span>{" "}
                                                  <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                      transferAmount -
                                                      commission.receiverTotalAmount
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix="UZS"
                                                    renderText={(value) => (
                                                      <b>{value}</b>
                                                    )}
                                                  />
                                                </li>
                                                <li>
                                                  <span>Receiver Name: </span>{" "}
                                                  <b>
                                                    {selectedRecipient.name}
                                                  </b>
                                                </li>
                                                <li>
                                                  <span>Receiver: </span>{" "}
                                                  <b>
                                                    {
                                                      selectedRecipient.masked_recipient
                                                    }
                                                  </b>
                                                </li>
                                                <li>
                                                  <span>Description: </span>{" "}
                                                  <input
                                                    type="text"
                                                    value={transferDescription}
                                                    onChange={(e) =>
                                                      setTransferDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    required
                                                  />
                                                </li>
                                                <li>
                                                  <span>
                                                    Transfer will be receive on:{" "}
                                                  </span>{" "}
                                                  <b>
                                                    {Moment().format(
                                                      "DD MMM yyyy HH:mm"
                                                    )}{" "}
                                                  </b>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <form className="form">
                                          <div className="checkbox">
                                            <input
                                              type="checkbox"
                                              id="confirm"
                                              required
                                            />{" "}
                                            <label htmlFor="confirm">
                                              I confirm the payment details
                                              above
                                            </label>
                                          </div>
                                          <div className="col-12">
                                            <div className="btn-border w-100">
                                              <a
                                                className="cmn-btn w-100"
                                                onClick={() =>
                                                  confirmTransfer()
                                                }
                                              >
                                                Confirm
                                              </a>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Transfer Popup start  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TransferCards;
