import { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth'
import Moment from 'moment'
import TransactionDetail from '../Common/TransactionDetail'
import CurrencyFormat from 'react-currency-format';

const PersonalTransactions = () => {
    Moment.locale('en');

    const [isTransactionDetailOpen, setIsTransactionDetailOpen] = useState(false);
    const [transactionData, setTransactionData] = useState(null);

    const toggleTransactionModal = (trnDetail) => {
        setTransactionData(trnDetail);
        setIsTransactionDetailOpen(!isTransactionDetailOpen);
    }

    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();

    const [transactionFilter, setTransactionFilter] = useState({ transactionType: "", currency: "", pageSize: 10 });
    const [transactionList, setTransactionList] = useState([]);
    const [lastTransactionId, setLastTransactionId] = useState(0)

    const clearFilter = () => {
        setTransactionFilter({ transactionType: "", currency: "", pageSize: 10 });
    }

    const getTransactionList = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...transactionFilter, lastTransactionId })
        };
        fetch('api/transaction', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            if (x.data[x.data.length - 1]) {
                                setLastTransactionId(x.data[x.data.length - 1].id)
                            }
                            setTransactionList(...transactionList, x.data);
                        }
                    });
                }
            })
    }

    useEffect(() => {
        setTransactionList([]);
        getTransactionList();
    }, [transactionFilter]);


    return (
        <>
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                            <div className="row">
                                <div className="col-lg-5 col-md-4">
                                    <h4>Transactions</h4>
                                </div>
                                <div className="col-lg-7 col-md-8">
                                    <div className="transactions-right d-flex align-items-center">
                                        <div className="flex-fill">
                                            <div className="form-group d-flex align-items-center">
                                                <img src="assets/images/icon/search.png" alt="icon" />
                                                <input type="text" placeholder="Type to search..." />
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)">Monthly Statement</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    <div className="top-items">
                                        <h6>All Transactions</h6>
                                        <div className="export-area">
                                            <ul className="d-flex align-items-center">
                                                <li><a href="javascript:void(0)"><img src="assets/images/icon/printer.png" alt="icon" />Print</a></li>
                                                <li><a href="javascript:void(0)"><img src="assets/images/icon/excel.png" alt="icon" />Excel</a></li>
                                                <li><a href="javascript:void(0)"><img src="assets/images/icon/pdf.png" alt="icon" />PDF</a></li>
                                                <li><a href="javascript:void(0)"><img src="assets/images/icon/csv.png" alt="icon" />CSV</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="filters-item">
                                        <div className="single-item">
                                            <select>
                                                <option value={1}>23 Nov 2021- 21 Feb 2022</option>
                                                <option value={2}>23 Feb 2021- 21 Mar 2022</option>
                                                <option value={3}>23 Mar 2021- 21 Apr 2022</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <select>
                                                <option value="DepositDebitCard">DepositDebitCard</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <button onClick={(()=> clearFilter())}>Clear Filters</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name/ Business</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    transactionList.map((transaction) => (
                                                        <tr onClick={(() => toggleTransactionModal(transaction))} key={transaction.tx_ref_relation_id} data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                            <th scope="row">
                                                                <p>{JSON.parse(transaction.json_data).other_side_title}</p>
                                                                <p className="mdr">{transaction.tx_type}</p>
                                                            </th>
                                                            <td>
                                                                <p>{Moment(transaction.insert_date).format('HH:mm')}</p>
                                                                <p className="mdr">{Moment(transaction.insert_date).format('DD MMM yyyy')}</p>
                                                            </td>
                                                            <td>
                                                                <p className={
                                                                    transaction.financial_process_status == 1 || transaction.financial_process_status == 11 ?
                                                                        "trx-pending" :
                                                                        transaction.financial_process_status == 2 ? "trx-inprogress" :
                                                                            transaction.financial_process_status == 3 || transaction.financial_process_status == 5 ? "trx-completed" :
                                                                                transaction.financial_process_status == 4 ? "trx-cancelled" : ""


                                                                }>
                                                                    {
                                                                        transaction.financial_process_status == 1 || transaction.financial_process_status == 11 ?
                                                                            "Pending" :
                                                                            transaction.financial_process_status == 2 ? "In Progress" :
                                                                                transaction.financial_process_status == 3 || transaction.financial_process_status == 5 ? "Completed" :
                                                                                    transaction.financial_process_status == 4 ? "Cancelled" : "Not Known"


                                                                    }
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transaction.base_amount - transaction.fee_amount}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={(transaction.deposit_flag == 1 ? "+" : "-") + (transaction.currency_info ? transaction.currency_info.currency_symbol : transaction.currency)}
                                                                    renderText={value => <p>{value}</p>}
                                                                />
                                                                <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transaction.fee_amount}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={transaction.currency_info ? transaction.currency_info.currency_symbol : transaction.currency}
                                                                    renderText={value => <p className="mdr">{value}</p>}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="transactions-popup">
                <div className="container-fruid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="transactionsMod" aria-hidden="true">
                                <TransactionDetail isOpen={isTransactionDetailOpen} toggleModal={toggleTransactionModal} closeOnOutsideClick={false} transactionData={transactionData}>

                                </TransactionDetail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
}

export default PersonalTransactions;