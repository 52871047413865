import React from "react";
import CurrencyFormat from "react-currency-format";

export const Card = ({cardLists}) => {
  return (
    <>
     {cardLists ? cardLists.map((card, index) => (
    <>
      <label className="bankname text-center ">
        <img
          className="rounded"
          style={{ maxWidth: "70px", maxHeight: "70px" }}
          src={`assets/images/${card.bank_logo}`}
        ></img>{" "}
        <br />
        <b>{card.cardBankName}</b>
      </label>

      <label className="cardname ">
        <CurrencyFormat
          value={card.balance}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={"text"}
          thousandSeparator={true}
          prefix={card.currency}
          renderText={(value) => (
            <>
              <h2
                style={{
                  fontSize: "18px",
                  color: "	#66BB6A",
                  fontWeight: "bold",
                  marginBottom: "-23px",
                }}
              >
                {value}
              </h2>
              {card.card_name}{" "}
              <label className="cardnumber ">
                {card.card_bin_first_six
                  ? card.card_bin_first_six.substring(0, 4) +
                    " " +
                    card.card_bin_first_six.substring(4, 6) +
                    "** **** " +
                    card.card_bin_last_four
                  : null}
              </label>
              <b
                style={{
                  fontWeight: "400",
                  color: "white",
                }}
              >
                {card.exp_month}/{card.exp_year}
              </b>
            </>
          )}
        />
      </label>
      </>
       )):null}
    </>
  );
};

export const CardParapay = (props) => {
  return (
    <>
      <span className="wrapper"></span>
      <div className="row card-title">
        <div className="col-10 ">
          <label className="bankname " style={{ marginLeft: "-70px" }}>
            <img
              style={{
                maxWidth: "160px",
              }}
              src="auth-assets/img/parapay/Parapay_blue_bg.png"
            ></img>{" "}
          </label>
        </div>
        <div className="col"></div>
        <label className="cardname">UZS {props.WalletBalance}</label>
      </div>

      <div className="row card-info">
        <div className="col-7">
          <label className="cardnumber " style={{ textAlign: "left" }}>
            {props.cardNumber}{" "}
            <img
              style={{
                maxWidth: "15px",
                maxHeight: "15px",
              }}
              src="assets/images/icon/copy_16px.png"
            ></img>{" "}
          </label>
        </div>
      </div>
    </>
  );
};


