import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Moment from "moment";
import TransactionDetail from "../Common/TransactionDetail";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Card } from "./Card";
import { width } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PersonalAccount = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();

  const [cardList, setCardList] = useState([]);
  const [currency, setCurrency] = useState("UZS");
  const [open, setOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("Info");

  const [deviceList, setDeviceList] = useState([]);

  const getCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            localStorage.setItem("cards", JSON.stringify(x.data));
            setCardList(x.data);
          }
        });
      }
    });
  };
  useEffect(() => {
    const cards = JSON.parse(localStorage.getItem("cards"));
    if (cards) {
      setCardList(cards);
    }
  }, []);

  const getDeletelCard = async (cardtoken) => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card/remove/" + cardtoken, requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            closeModalButtonRef.current.click();
            setMessage("Card Deletion Successful");
            setOpen(true);
            setSuccessful(true);
          }
        });
      }
    });
  };

  const openModalButtonRef = useRef();
  const closeModalButtonRef = useRef();
  const [cardDetail, setCardDetail] = useState([]);

  const getDetailCard = async (cardtoken) => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card/detail/" + cardtoken, requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setCardDetail(x.data);
            openModalButtonRef.current.click();
          }
        });
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getDeviceList = async () => {
    const requestOptions = {
      method: "GET",
    };

    fetch("api/auth/trusteddevices", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setDeviceList(x.data);
          }
        });
      }
    });
  };

  const [currentPassword, setCurrentPassword] = useState([]);
  const [newPassword, setNewPassword] = useState([]);
  const [confirmNewPassword, setConfirmNewPassword] = useState([]);

  const confirmPass = () => {
    if (
      newPassword == confirmNewPassword &&
      newPassword.length > 0 &&
      confirmNewPassword.length > 0
    ) {
      document.getElementById("isMatching").style.display = "block";
    } else {
      document.getElementById("isMatching").style.display = "none";
    }
  };

  useEffect(() => {
    getCardList();
    getDeviceList();
  }, []);

  const [firstName, setFirstName] = useState(auth?.user?.contact?.name);
  const [lastName, setLatstName] = useState(auth?.user?.contact?.surname);
  const [email, setEmail] = useState(auth?.user?.account?.email);
  const [phone, setPhone] = useState(
    formatPhoneNumberIntl(auth?.user?.account?.phone_number)
  );
  const [address, setAddress] = useState(auth?.user?.contact?.address);

  function handleFirsNameChange(event) {
    setFirstName(event.target.value);
  }
  function handleLastNameChange(event) {
    setLatstName(event.target.value);
  }
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  function handlePhoneChange(event) {
    setPhone(event.target.value);
  }
  function handleAddressChange(event) {
    setAddress(event.target.value);
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={successful ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      {loading ? <div className="preloader"></div> : null}

      <section className="dashboard-section body-collapse account">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row">
                <div className="col-xxl-3 col-xl-4 col-md-6">
                  <div className="owner-details">
                    <div className="profile-area">
                      <div className="profile-img">
                        <img
                          src="assets/images/owner-profile.png"
                          alt="image"
                        />
                      </div>
                      <div className="name-area">
                        <h6>
                          {auth?.user?.contact?.name}{" "}
                          {auth?.user?.contact?.surname}
                        </h6>
                        <p className="active-status">Active</p>
                      </div>
                    </div>
                    <div className="owner-info">
                      <ul>
                        <li>
                          <p>Account ID:</p>
                          <span className="mdr">
                            {auth?.user?.account?.account_number}
                          </span>
                        </li>
                        <li>
                          <p>Joined:</p>
                          <span className="mdr">
                            {Moment(auth?.user?.account?.insert_date).format(
                              "DD MMM yyyy"
                            )}
                          </span>
                        </li>
                        <li>
                          <p>Level:</p>
                          <span className="mdr">
                            {auth?.user?.account?.kyc_level}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="owner-action">
                      <Link to="/auth/logout">
                        <img src="assets/images/icon/logout.png" alt="image" />
                        Logout
                      </Link>
                      <Link to="#" id="deleteAccountButton" className="delete">
                        <img
                          src="assets/images/icon/delete-2.png"
                          alt="image"
                        />
                        Delete Account
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-9 col-xl-8">
                  <div className="tab-main">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="account-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#account"
                          type="button"
                          role="tab"
                          aria-controls="account"
                          aria-selected="true"
                        >
                          Account
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="security-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#security"
                          type="button"
                          role="tab"
                          aria-controls="security"
                          aria-selected="false"
                        >
                          Security
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="payment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#payment"
                          type="button"
                          role="tab"
                          aria-controls="payment"
                          aria-selected="false"
                        >
                          Payment Methods
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="notification-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#notification"
                          type="button"
                          role="tab"
                          aria-controls="notification"
                          aria-selected="false"
                        >
                          Notification
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content mt-40">
                      <div
                        className="tab-pane fade show active"
                        id="account"
                        role="tabpanel"
                        aria-labelledby="account-tab"
                      >
                        <div className="upload-avatar">
                          <div className="avatar-left d-flex align-items-center">
                            <div className="profile-img">
                              <img
                                src="assets/images/owner-profile-2.png"
                                alt="image"
                              />
                            </div>
                            <div className="instraction">
                              <h6>Your Avatar</h6>
                              <p>Profile picture size: 400px x 400px</p>
                            </div>
                          </div>
                          <div className="avatar-right">
                            <div className="file-upload">
                              <div className="right-area">
                                <label className="file">
                                  <input type="file" />
                                  <span class="file-custom"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form action="#">
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="single-input">
                                <label for="fName">First Name</label>
                                <input
                                  type="text"
                                  id="fName"
                                  placeholder={auth?.user?.contact?.name}
                                  value={firstName}
                                  onChange={handleFirsNameChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label for="lName">Last Name</label>
                                <input
                                  type="text"
                                  id="lName"
                                  placeholder={auth?.user?.contact?.surname}
                                  value={lastName}
                                  onChange={handleLastNameChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="single-input">
                                <label for="email">Email</label>
                                <div className="row input-status d-flex align-items-center">
                                  <div className="col-6">
                                    <input
                                      type="text"
                                      id="email"
                                      placeholder={auth?.user?.account?.email}
                                      value={email}
                                      onChange={handleEmailChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <span className="pending">
                                      <img
                                        src="assets/images/icon/pending.png"
                                        alt="icon"
                                      />
                                      E-mail confirmation in pending
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="single-input">
                                <label for="phone">Phone</label>
                                <div className="row input-status d-flex align-items-center">
                                  <div className="col-6">
                                    <input
                                      type="text"
                                      id="phone"
                                      placeholder={formatPhoneNumberIntl(
                                        auth?.user?.account?.phone_number
                                      )}
                                      value={phone}
                                      onChange={handlePhoneChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <span className="confirm">
                                      <img
                                        src="assets/images/icon/confirm.png"
                                        alt="icon"
                                      />
                                      Phone number confirm
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="single-input file">*/}
                            {/*        <label>ID Confirmation documents</label>*/}
                            {/*        <div className="row input-status d-flex align-items-center">*/}
                            {/*            <div className="col-6">*/}
                            {/*                <div className="file-upload">*/}
                            {/*                    <div className="right-area">*/}
                            {/*                        <label className="file">*/}
                            {/*                            <input type="file" />*/}
                            {/*                            <span class ="file-custom"></span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-6">*/}
                            {/*                <span className="notconfirm">*/}
                            {/*                    <img src="assets/images/icon/not-confirm.png" alt="icon" />*/}
                            {/*                    Person not confirmed*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-md-12">
                              <div className="single-input">
                                <label for="address">Address</label>
                                <input
                                  type="text"
                                  id="address"
                                  placeholder={auth?.user?.contact?.address}
                                  value={address}
                                  onChange={handleAddressChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="btn-border">
                                <button className="cmn-btn">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="security"
                        role="tabpanel"
                        aria-labelledby="security-tab"
                      >
                        <div className="single-content authentication d-flex align-items-center justify-content-between">
                          <div className="left">
                            <h5>Two Factor Authentication</h5>
                            <p>
                              Two-Factor Authentication (2FA) can be used to
                              help protect your account
                            </p>
                          </div>
                          <div className="right">
                            <button>Enable</button>
                          </div>
                        </div>
                        <div className="change-pass mb-40">
                          <div className="row">
                            <div className="col-sm-6">
                              <h5>Change Password</h5>
                              <p>
                                You can always change your password for security
                                reasons or reset your password in case you
                                forgot it.
                              </p>
                              <a href="javascript:void(0)">Forgot password?</a>
                            </div>
                            <div className="col-sm-6">
                              <form action="#">
                                <div className="row justify-content-center">
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label for="current-password">
                                        Current password
                                      </label>
                                      <input
                                        type="password"
                                        id="current-password"
                                        placeholder="Current password"
                                        value={currentPassword}
                                        onChange={(e) =>
                                          setCurrentPassword(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label for="new-password">
                                        New password
                                      </label>
                                      <input
                                        type="password"
                                        id="new-password"
                                        placeholder="New password"
                                        value={newPassword}
                                        onChange={(e) =>
                                          setNewPassword(
                                            e.target.value,
                                            confirmPass()
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label for="confirm-password">
                                        Confirm New password
                                      </label>
                                      <input
                                        type="password"
                                        id="confirm-password"
                                        placeholder="Confirm New password"
                                        value={confirmNewPassword}
                                        onChange={(e) =>
                                          setConfirmNewPassword(
                                            e.target.value,
                                            confirmPass()
                                          )
                                        }
                                      />
                                      <small
                                        id="isMatching"
                                        style={{
                                          color: "red",
                                          display: "none",
                                        }}
                                      >
                                        password do not match!
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="btn-border w-100">
                                      <button className="cmn-btn w-100">
                                        Update password
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="single-content additional-security">
                          <h5>Additional security</h5>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SMS recovery</h6>
                              <p>Number ending with 1234</p>
                            </div>
                            <div className="right">
                              <button className="active">Disable SMS</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>Autheticator App</h6>
                              <p>Google Authenticator</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SSL Certificate</h6>
                              <p>Secure Sockets Layer</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                        </div>
                        <div className="single-content your-devices">
                          <div className="head-item d-flex align-items-center justify-content-between">
                            <h5>Your devices</h5>
                            <a href="javascript:void(0)">
                              Log out on all devices
                            </a>
                          </div>

                          {deviceList
                            ? deviceList.map((device) => (
                                <div className="single-setting">
                                  <div className="left">
                                    <div className="icon-area">
                                      <img
                                        src="assets/images/icon/iphone.png"
                                        alt="icon"
                                      />
                                    </div>
                                    <div className="text-area">
                                      <h6> {device.deviceName}</h6>
                                      <p>New York City · June 20 at 14:00</p>
                                    </div>
                                  </div>
                                  <div className="right">
                                    <button>Log out</button>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div
                        className="tab-pane pb-120 fade"
                        id="payment"
                        role="tabpanel"
                        aria-labelledby="payment-tab"
                      >
                        <div className="card-area">
                          <h6>Linked Payment system</h6>
                          <div className="card-content d-flex flex-wrap">
                            {cardList
                              ? cardList.map((card) => (
                                  <button
                                    style={{
                                      maxWidth:"250px",
                                      maxHeight:"130px",
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      backgroundSize: "cover",
                                      borderRadius:"8px"
                                    }}
                                    className="cc-card-item parapay"
                                    key={card.card_token}
                                    onClick={() =>
                                      getDetailCard(card.card_token)
                                    }
                                  >
                                    <Card cardLists={cardList} />
                                  </button>
                                ))
                              : null}

                            <Link style={{marginTop: "10px"}} to="/personal/add-card" >
                              <div className="single-card" style={{minWidth:"250px",
                                      minHeight:"130px",marginLeft:"11px",borderRadius:"8px !important"}}>
                                <button type="button" className="reg w-100">
                                  <img
                                    src="assets/images/add-new.png"
                                    alt="image"
                                    className="w-100"
                                  />
                                </button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="notification"
                        role="tabpanel"
                        aria-labelledby="notification-tab"
                      >
                        <div className="notification-single">
                          <h6>Announcements</h6>
                          <label className="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Send payment</h6>
                          <label className="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Receive a payment</h6>
                          <label className="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Request payment</h6>
                          <label className="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Have a problem with a payment</h6>
                          <label className="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Special Offers</h6>
                          <label className="switch">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div class="add-card">
        <div class="container-fruid">
          <div class="row">
            <div class="col-lg-6">
              <div class="modal fade" id="addcardMod" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header justify-content-between">
                      <h6>Add Card</h6>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <form action="#">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="single-input">
                            <label for="cardNumber">Card Name</label>
                            <input
                              type="text"
                              id="cardName"
                              placeholder="My Card"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="single-input">
                            <label for="cardNumber">Card Number</label>
                            <input
                              type="text"
                              id="cardNumber"
                              placeholder="5890 - 6858 - 6332 - 9843"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="single-input">
                            <label for="cardHolder">Card Holder</label>
                            <input
                              type="text"
                              id="cardHolder"
                              placeholder="Alfred Davis"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="single-input">
                            <label for="month">Month</label>
                            <input type="text" id="month" placeholder="12" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="single-input">
                            <label for="year">Year</label>
                            <input type="text" id="year" placeholder="2025" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="btn-border w-100">
                            <button class="cmn-btn w-100">Add Card</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="transactions-popup mycard">
        <div class="container-fruid">
          <div class="row">
            <div class="col-lg-6">
              <div class="modal fade" id="transactionsMod1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header justify-content-between">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ref={closeModalButtonRef}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>

                      <button
                        style={{ display: "none" }}
                        ref={openModalButtonRef}
                        data-bs-toggle="modal"
                        data-bs-target="#transactionsMod1"
                        aria-label="Close"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <div class="main-content">
                      <div class="row">
                        <div class="col-sm-4">
                          <h5>{cardDetail.card_name}</h5>
                          <div class="icon-area">
                            <div
                              style={{
                                marginTop: "-20px",
                                marginBottom: "8px",
                                marginLeft: "-5px",
                                maxWidth: "272px",
                              
                              }}
                              className="cc-card-item parapay"
                            >
                              <Card cardLists={cardList} />
                            </div>

                            <div class="row mt-5">
                              <div class="col">
                                <button
                                  onClick={() =>
                                    getDeletelCard(cardDetail.card_token)
                                  }
                                  className="rounded border btn btn-outline-danger w-100"
                                >
                                  Delete
                                </button>
                              </div>
                              <div class="col">
                                <button className="rounded border btn btn-outline-warning w-100">
                                  Report
                                </button>
                              </div>
                              <div class="col mt-3">
                                <button className="rounded border btn btn-outline-info w-100">
                                  Enable Card Tracking
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-8">
                          <div class="right-area">
                            <div class="top-area d-flex align-items-center justify-content-between">
                              <div class="card-details d-flex align-items-center">
                                <img
                                  src="assets/images/icon/mastercard.png"
                                  alt="image"
                                />
                                <span>
                                  {cardDetail.card_bin_first_six
                                    ? cardDetail.card_bin_first_six.substring(
                                        0,
                                        4
                                      ) +
                                      " " +
                                      cardDetail.card_bin_first_six.substring(
                                        4,
                                        6
                                      ) +
                                      "** **** " +
                                      cardDetail.card_bin_last_four
                                    : null}
                                </span>
                              </div>
                            </div>
                            <ul class="payment-details">
                              <li>
                                <span>Card Holder:</span>
                                <span>{cardDetail.card_holder_name}</span>
                              </li>
                              <li>
                                <span>Expires:</span>
                                <span>
                                  {cardDetail.exp_month}/{cardDetail.exp_year}
                                </span>
                              </li>
                              <li>
                                <span>Card Number:</span>
                                <span>
                                  {cardDetail.card_bin_first_six
                                    ? cardDetail.card_bin_first_six.substring(
                                        0,
                                        4
                                      ) +
                                      " " +
                                      cardDetail.card_bin_first_six.substring(
                                        4,
                                        6
                                      ) +
                                      "** **** " +
                                      cardDetail.card_bin_last_four
                                    : null}
                                </span>
                              </li>
                              <li>
                                <span>Total Balance:</span>
                                <span>{cardDetail.balance}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalAccount;
