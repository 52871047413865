import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth'

const CheckUser = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        checkAuthorization();
    }, []);

    const checkAuthorization = async () => {
        const requestOptions = {
            method: 'GET'
        };
        fetch('api/auth/checkauthorization', requestOptions)
            .then(response => {
                response.json().then(x => {
                    if (x.status) {
                        setAuth({ user: x.data.user, roles: x.data.roles });
                        navigate(x.data.homepage);
                    } else {
                        setAuth({});
                        navigate("/auth/login");
                    }
                });
            })
    }

    return (
        <>
            <div className="preloader"></div>
        </>
    );
}

export default CheckUser;