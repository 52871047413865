import { Outlet } from 'react-router-dom';
import { NavMenu } from '../NavMenu';

const Layout = () => {

    return (
        <div>
            <NavMenu />
            <Outlet />
        </div>
    );
}

export default Layout;
