import { useEffect, useRef, useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";

const PayingBills = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  return (
    <>
      <section className="dashboard-section body-collapse pay step crypto deposit-money">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-between pb-120">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="table-area">
                    <div className="head-area">
                      <h5>
                        <b>Payment</b>
                      </h5>
                    </div>

                    <div class="row pb-120 ">
                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="/personal/favorites">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/fav.png"
                                  />
                                  <br />
                                  <span>Favorite</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="/personal/mobile-operators">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/mob.png"
                                  />
                                  <br />
                                  <span>Mobile Operators</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#internet-packages">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/netpac.png"
                                  />
                                  <br />
                                  <span>Internet Packages</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#health">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/health.png"
                                  />
                                  <br />
                                  <span>Health</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#loan-repayment">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/loanre.png"
                                  />
                                  <br />
                                  <span>Loan Repayment</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#hostinger-domain">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/domain.png"
                                  />
                                  <br />
                                  <span>Hostinger and Domain</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#education">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/education.png"
                                  />
                                  <br />
                                  <span>Education</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#utilty-payments">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/utilty.png"
                                  />
                                  <br />
                                  <span>Utilty Payments</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#taxi">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/taxi.png"
                                  />
                                  <br />
                                  <span>Taxi</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#charity">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/charity.png"
                                  />
                                  <br />
                                  <span>Charity</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#taxes">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/tax.png"
                                  />
                                  <br />
                                  <span>Taxes</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#telephony">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/tel.png"
                                  />
                                  <br />
                                  <span>Telephony</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#tv">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/tv.png"
                                  />
                                  <br />
                                  <span>TV</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#insurance">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/insurance.png"
                                  />
                                  <br />
                                  <span>Insurance</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#public-dmv">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/dmv.png"
                                  />
                                  <br />
                                  <span>Public Services and DMV</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>

                      <div class="col-xxl-3 col-xl-3 col-md-5">
                        <Link to="#entertainment-leisure">
                          <div className="sigle-card" key="FromAccount">
                            <label htmlFor="FromAccount">
                              <span className="wrapper" />

                              <div
                                className="cc-card-item parapay"
                                style={{ width: "200px" }}
                              >
                                <div className="icon-area">
                                  <img
                                    className="parapayaccount"
                                    src="assets/images/icon/entt.png"
                                  />
                                  <br />
                                  <span>Entertainment and leisure</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PayingBills;
