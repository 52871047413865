import { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [userchecked, setUserchecked] = useState(false);
    useEffect(async () => {
        if (auth?.user) {
        } else {
            const requestOptions = {
                method: 'GET'
            };
            const response = await fetch('api/auth/checkauthorization', requestOptions);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    await setAuth({ user: data.data.user, roles: data.data.roles });
                }
            }
        }
        setUserchecked(true);
    }, []);

    return (
        userchecked ?
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/auth/unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/" state={{ from: location }} replace />
            :
            null
    );
}

export default RequireAuth;