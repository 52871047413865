import { Component, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import useImportScript from "../../hooks/useImportScript";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import LanguageSwitcher, { languages } from "../Common/LanguageSwitcher";
import * as React from "react";
import { NavLink } from "reactstrap";

const PersonalLayout = () => {
  const { auth } = useAuth();
  const { lang, setLang } = useLang();
  const [loading, setLoading] = useState(false);
  const [currenctPath, setCurrentPath] = useState("/personal");

  // const itemList = [
  //   {
  //     id: 1,
  //     name: "Dashboard",
  //     image: "dashIMG.png",
  //     location: "/personal",
  //   },
  //   {
  //     id: 1,
  //     name: "Transactions",
  //     image: "transactionsIMG.png",
  //     location: "/personal/transactions",
  //   },
  // ];

  // const [filteredList, setFilteredList] = useState([]);

  // const filterBySearch = (event) => {
  //   const query = event.target.value;
  //   var updatedList = [...itemList];
  //   updatedList = updatedList.filter((item) => {
  //     return (
  //       item.name
  //         .toString()
  //         .toLowerCase()
  //         .indexOf(query.toString().toLowerCase()) !== -1
  //     );
  //   });
  //   query.length == 0 ? setFilteredList([]) : setFilteredList(updatedList);
  // };


  // const getSearch = () => {
  //   var getValue = document.getElementById("searcInput");
  //   if (getValue.value != "") {
  //     getValue.value = "";
  //   }
  //   setFilteredList([]);
  // };

  // const pageList = filteredList.map((item, index) => (
  //   <li key={index} className="mt-2 p-2 w-full border rounded">
  //     <Link onClick={() => getSearch()} to={item.location}>
  //       <img
  //         src={`assets/images/icon/${item.image}`}
  //         alt="page"
  //         style={{ width: "50px", height: "50px" }}
  //       />{" "}
  //       {item.name}
  //     </Link>
  //   </li>
  // ));

  useImportScript("assets/js/jquery.min.js");
  useImportScript("assets/js/bootstrap.min.js");
  useImportScript("assets/js/jquery-ui.js");
  useImportScript("assets/js/plugin/apexcharts.js");
  useImportScript("assets/js/plugin/jquery.nice-select.min.js");
  useImportScript("assets/js/plugin/waypoint.min.js");
  useImportScript("assets/js/plugin/wow.min.js");
  useImportScript("assets/js/plugin/plugin.js");
  useImportScript("assets/js/main.js");

  const history = useLocation();

  useEffect(() => {
    setCurrentPath(history.pathname);
  }, [history]);

  const changeLang = async (newlang) => {
    const requestOptions = {
      method: "GET",
    };
    setLoading(true);
    fetch("api/lang/" + newlang, requestOptions)
      .then((response) => {
        if (response.ok) {
          setLang(newlang);
          window.location.reload(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/apexcharts.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/arafat-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      {loading ? <div className="preloader"></div> : null}

      <div className="preloader" id="preloader"></div>

      <a href="#" className="scrollToTop">
        <i className="fas fa-angle-double-up"></i>
      </a>

      <header className="header-section body-collapse">
        <div className="overlay">
          <div className="container-fruid">
            <div className="row d-flex header-area">
              <div className="navbar-area d-flex align-items-center justify-content-between">
                <div className="sidebar-icon">
                  <img src="assets/images/icon/menu.png" alt="icon" />
                </div>
                <form action="#" className="flex-fill">
                  <div className="form-group d-flex align-items-center">
                    <img src="assets/images/icon/search.png" alt="icon" />
                    <input
                      type="text"
                      placeholder="Type to search..."
                      // id="searcInput"
                      // onChange={filterBySearch}
                    />
                  </div>
                  {/* {filteredList.length == 0 ? null : (
                    <ul
                      className="w-full bg-white p-2 rounded"
                      style={{
                        position: "absolute",
                        zIndex: "10000",
                        width: "63.5%",
                      }}
                    >
                      {pageList}
                    </ul>
                  )} */}
                </form>
                <div className="dashboard-nav">
                  <div className="single-item language-area">
                    <div className="language-btn">
                      <img src="assets/images/icon/lang.png" alt="icon" />
                    </div>
                    <ul className="main-area language-content">
                      <li
                        className={lang.lang == "tr" ? "active" : ""}
                        onClick={() => {
                          changeLang("tr");
                        }}
                      >
                        Türkçe
                      </li>
                      <li
                        className={lang.lang == "en" ? "active" : ""}
                        onClick={() => {
                          changeLang("en");
                        }}
                      >
                        English (US)
                      </li>
                    </ul>
                  </div>
                  <div className="single-item notifications-area">
                    <div className="notifications-btn">
                      <img
                        src="assets/images/icon/bell.png"
                        className="bell-icon"
                        alt="icon"
                      />
                    </div>
                    <div className="main-area notifications-content">
                      <div className="head-area d-flex justify-content-between">
                        <h5>Notifications</h5>
                        <span className="mdr">4</span>
                      </div>
                      <ul>
                        <li>
                          <a href="#" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/user-1.png"
                                className="max-un"
                                alt="image"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                You received a payment of <b>$134.00</b> from{" "}
                                <b>Anna Green</b>
                              </p>
                              <p className="mdr time-area">09.39AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/user-2.png"
                                className="max-un"
                                alt="image"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                <b>James Deed</b> requested a payment of
                                <b>£890.00</b>
                              </p>
                              <p className="mdr time-area">08.09AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/master-card.png"
                                className="max-un"
                                alt="image"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                Your new payment method has beed added
                                successfully
                              </p>
                              <p className="mdr time-area">09.39AM</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                        <li>
                          <a href="#" className="d-flex">
                            <div className="img-area">
                              <img
                                src="assets/images/user-3.png"
                                className="max-un"
                                alt="image"
                              />
                            </div>
                            <div className="text-area">
                              <p className="mdr">
                                You received a payment of <b>$250.00</b> from
                                Goerge Michael
                              </p>
                              <p className="mdr time-area">Yesterday</p>
                            </div>
                          </a>
                          <i className="fa-solid fa-caret-right" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="single-item user-area">
                    <div className="profile-area d-flex align-items-center">
                      <span className="user-profile">
                        <img src="assets/images/avatar.png" alt="User" />
                      </span>
                      <i className="fa-solid fa-sort-down" />
                    </div>
                    <div className="main-area user-content">
                      <div className="head-area d-flex align-items-center">
                        <div className="profile-img">
                          <img src="assets/images/avatar-2.png" alt="User" />
                        </div>
                        <div className="profile-head">
                          <a href="#">
                            <h5>{auth?.user?.account?.account_number}</h5>
                          </a>
                          <p className="wallet-id">Wallet ID: 6264849965</p>
                        </div>
                      </div>
                      <ul>
                        <li className="border-area">
                          <Link to="/personal/account">
                            <i className="fas fa-cog" />
                            Settings
                          </Link>
                        </li>
                        <li>
                          <Link to="/auth/logout">
                            <i className="fas fa-sign-out" />
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-wrapper">
                <div className="close-btn">
                  <i className="fa-solid fa-xmark" />
                </div>
                <div className="sidebar-logo">
                  <Link to="/personal">
                    <img
                      src="auth-assets/img/parapay/Parapay_icon_blue_bg.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                <ul>
                  <li className={currenctPath == "/personal" ? "active" : ""}>
                    <Link to="/personal">
                      <img
                        src="assets/images/icon/dashboard.png"
                        alt="Dashboard"
                      />{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currenctPath == "/personal/transactions" ? "active" : ""
                    }
                  >
                    <Link to="/personal/transactions">
                      <img
                        src="assets/images/icon/transactions.png"
                        alt="Transactions"
                      />{" "}
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currenctPath.includes("/personal/transfer")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/personal/transfer">
                      <img
                        src="assets/images/icon/exchange.png"
                        alt="Transfer"
                      />{" "}
                      <span>Transfer</span>
                    </Link>
                  </li>

                  <li
                    className={
                      currenctPath.includes("/personal/reports") ? "active" : ""
                    }
                  >
                    <Link to="/personal/reports">
                      <img src="assets/images/icon/receive.png" alt="Reports" />{" "}
                      <span>Reports</span>
                    </Link>
                  </li>

                  <li
                    className={
                      currenctPath.includes("/personal/payment") ? "active" : ""
                    }
                  >
                    <Link to="/personal/payment">
                      <img src="assets/images/icon/pay.png" alt="Receive" />{" "}
                      <span>Paying bills</span>
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*    <a href="receive-step-1.html">*/}
                  {/*        <img src="assets/images/icon/receive.png" alt="Receive" /> <span>Receive</span>*/}
                  {/*    </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*    <a href="money-exchange.html">*/}
                  {/*        <img src="assets/images/icon/exchange.png" alt="Exchange" /> <span>Exchange</span>*/}
                  {/*    </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*    <a href="recipients.html">*/}
                  {/*        <img src="assets/images/icon/recipients.png" alt="Recipients" /> <span>Recipients</span>*/}
                  {/*    </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*    <a href="crypto.html">*/}
                  {/*        <img src="assets/images/icon/crypto.png" alt="Crypto" /> <span>Crypto</span>*/}
                  {/*    </a>*/}
                  {/*</li>*/}
                  <li
                    className={
                      currenctPath.includes("/personal/deposit") ? "active" : ""
                    }
                  >
                    <Link to="/personal/deposit">
                      <img src="assets/images/icon/deposit.png" alt="Deposit" />{" "}
                      <span>Deposit Money</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currenctPath.includes("/personal/withdraw")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/personal/withdraw">
                      <img
                        src="assets/images/icon/withdraw.png"
                        alt="Withdraw"
                      />{" "}
                      <span>Withdraw Money</span>
                    </Link>
                  </li>
                </ul>
                <ul className="bottom-item">
                  <li>
                    <Link to="/personal/account">
                      <img src="assets/images/icon/account.png" alt="Account" />{" "}
                      <span>Account</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <img src="assets/images/icon/support.png" alt="Support" />{" "}
                      <span>Support</span>
                    </a>
                  </li>
                  <li>
                    <Link to="/auth/logout">
                      <img src="assets/images/icon/quit.png" alt="Quit" />{" "}
                      <span>Quit</span>
                    </Link>
                  </li>
                </ul>
                <div className="pt-120">
                  <div className="invite-now">
                    <div className="img-area">
                      <img
                        src="assets/images/invite-now-illus.png"
                        alt="Image"
                      />
                    </div>
                    <p>Invite your friend and get $25</p>
                    <a href="#" className="cmn-btn">
                      Invite Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Outlet />
    </>
  );
};

export default PersonalLayout;
