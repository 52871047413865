import React from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";

const Posts = ({ cardReportLists }) => {
  // console.log("tests"+ JSON.stringify(cardReportLists));
  Moment.locale("en");
  
  return (
    <>
      {cardReportLists ? cardReportLists.map((card, index) => (
        <tr key={index}>
        <th scope="row">
          <p>
            <b>{card.from_wallet_flag ? "wallet" : card.card_masked}</b>
          </p>
        </th>
        <td>
          <p>
            <b>{Moment(card.insert_date).format("HH:mm")}</b>
          </p>
          <p className="mdr">
            {Moment(card.insert_date).format("DD MMM yyyy")}
          </p>
        </td>
        <td>
          <p className="text-success">
            <b>Completed</b>
          </p>
        </td>
        <td>
          <CurrencyFormat
            decimalScale={2}
            fixedDecimalScale={true}
            value={card.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={(card.deposit_flag == 1 ? "+" : "-") + card.currency}
            renderText={(value) => (
              <p className="text-danger" style={{ fontWeight: "bold" }}>
                {value}
              </p>
            )}
          />
        </td>
        </tr>
      )):null}
    </>
  );
};
export default Posts;