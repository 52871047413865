import { useEffect, useRef, useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const MobileOperators = () => {
  Moment.locale("en");
  Modal.setAppElement("body");
  const { auth } = useAuth();

  return (
    <>
      <section className="dashboard-section body-collapse pay step crypto deposit-money">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-between pb-120">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="table-area">
                    <div className="head-area">
                      <h5>
                        <b>Mobile Operators</b>
                      </h5>
                    </div>

                    <div class="row pb-120">
                      <div class="col-lg-2 col-md-5">
                        <Link to="#ucell" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/ucell.png"
                              alt="icon"
                            />
                          </div>
                        </Link>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/perfectum.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a
                          href="javascript:void(0)"
                          class="single-item"
                          data-bs-toggle="modal"
                          data-bs-target="#addcardMod"
                        >
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/beeline.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/beelinezk.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/lifecell.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/turkcell.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/uztelecom.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/emptyOperator.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/emptyOperator.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/emptyOperator.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/emptyOperator.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-2 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/emptyOperator.png"
                              alt="icon"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="add-card">
        <div class="container-fruid">
          <div class="row">
            <div class="col-lg-6">
              <div class="modal fade" id="addcardMod" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header justify-content-between">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <form action="#">
                      <div class=" d-flex justify-content-center icon-area ">
                        <img
                        className="rounded border p-2"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                          src="assets/images/icon/beeline.png"
                          alt="icon"
                        />
                        
                      </div><span className="d-flex justify-content-center font-weight-bold">Beeline</span>
                      <br />

                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="single-input">
                            <label for="phoneNumber">Phone Number</label>
                            <input
                              type="text"
                              id="phoneNumber"
                              defaultValue={formatPhoneNumberIntl(
                                auth?.user?.account?.phone_number
                              )}
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="single-input">
                            <label for="phoneNumber">Payment amount</label>
                            <input
                              type="text"
                              id="paymentAmount"
                              placeholder="Enter payment amount"
                            />
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="btn-border w-100">
                            <button class="cmn-btn w-100">Continue</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileOperators;
