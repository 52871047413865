import { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Moment from "moment";
import TransactionDetail from "../Common/TransactionDetail";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import CurrencyFormat from "react-currency-format";
import { Link, useNavigate } from "react-router-dom";

const AddCard = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  const otpRef = useRef();
  const errRef = useRef();

  const [otpkey, setOtpkey] = useState("");
  const [otpToken, setOtpToken] = useState("");

  const closeModalButtonRef = useRef();
  const openModalButtonRef = useRef();

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    otpRef.current.focus();
  }, []);

    const confirmSubmit = async (e) => {
        e.preventDefault();

        var otpkeyVal = await otpkey;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: otpToken, code: otpkeyVal }),
        };
        setLoading(true);
        fetch("api/card/otp/", requestOptions)
            .then((response) => {
                if (response.ok) {
                    response.json().then((x) => {
                        if (x.status) {
                            if (closeModalButtonRef) closeModalButtonRef.current.click();
                            navigate("/");
                        } else {
                            toggleErrorModal(x.message);
                        }
                    });
                } else {
                    response.json().then((x) => {
                        toggleErrorModal(x.message);
                    });
                }
            })
            .finally(() => {
                setOtpkey("");
                setLoading(false);
            });
    };

  const [isTransactionDetailOpen, setIsTransactionDetailOpen] = useState(false);
  const [transactionData, setTransactionData] = useState(null);

  const toggleTransactionModal = (trnDetail) => {
    setTransactionData(trnDetail);
    setIsTransactionDetailOpen(!isTransactionDetailOpen);
  };

  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [modalIsError, setModalIsError] = useState(true);

  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [cardList, setCardList] = useState([]);
  const [currency, setCurrency] = useState("UZS");
  const [masterBalance, setMasterBalance] = useState(0.0);
  const [transactionFilter, setTransactionFilter] = useState({
    lastTransactionId: 0,
    transactionType: "",
    currency: "",
    pageSize: 10,
  });
  const [transactionList, setTransactionList] = useState([]);

  const defaultNewCard = {
    card_bin: "",
    card_holder_name: "",
    card_name: "",
    exp_month: "",
    exp_year: "",
  };
  const [newCard, setNewCard] = useState(defaultNewCard);

  const getCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setCardList(x.data);
          }
        });
      }
    });
  };

  const errorModalStyles = {
    overlay: {
      backgroundColor: "#00000070",
    },
  };

  const toggleErrorModal = (msg) => {
    setErrorMessage(msg);
    setErrorIsOpen(!errorIsOpen);
  };

  const getTransactionList = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(transactionFilter),
    };
    fetch("api/transaction", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setTransactionList(x.data);
          }
        });
      }
    });
  };

  const newCreditcardHandleChange = (e) => {
    const value = e.target.value;
    setNewCard({
      ...newCard,
      [e.target.name]: value,
    });
  };

    const addCreditCardSubmit = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newCard),
        };
        setLoading(true);
        fetch("api/card", requestOptions)
            .then((response) => {
                if (response.ok) {
                    response.json().then((x) => {
                        if (x.status) {
                            if (x.data.requireotp) {
                                setOtpToken(x.data.cardid);
                                openModalButtonRef.current.click();
                            } else {
                                setModalIsError(false);
                                toggleErrorModal("Card successfully added");
                                getCardList();
                            }
                        } else {
                            setModalIsError(true);
                            toggleErrorModal(x.message);
                        }
                    });
                } else {

                    toggleErrorModal("Server error");
                }
            })
            .finally(() => {
                setNewCard(defaultNewCard);
                setLoading(false);
            });
    };

  useEffect(() => {
    getCardList();
    getTransactionList();
    setMasterBalance(
      auth.user.wallets
        .filter((x) => x.currency == currency)
        .reduce(
          (a, v) =>
            (a = a + v.cash_available_balance + v.credit_available_balance),
          0
        )
    );
  }, []);

  return (
    <>
      {loading ? <div className="preloader"></div> : null}
      <section className="dashboard-section body-collapse ">
        <div className="overlay pt-120 ">
          <div className="container-fruid ">
            <div className="row">
              <div className="col-12">
                <div className="section-content">
                  <div className="add-card">
                    <div className="container-fruid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div id="addcardMod">
                            <div
                              className="modal-dialog modal-dialog-centered"
                              style={{ width: "120px !important" }}
                            >
                              <div className="modal-content">
                                <div className="modal-header justify-content-center">
                                  <h6>ADD CARD</h6>
                                  <hr />
                                </div>
                                {/**/}
                                <form onSubmit={addCreditCardSubmit}>
                                  <div className="row justify-content-center">
                                    <div className="col-md-6">
                                      <div className="single-input">
                                        <label htmlFor="cardNumber">
                                          Card Name
                                        </label>
                                        <input
                                          type="text"
                                          name="card_name"
                                          placeholder="My Card"
                                          autoComplete="off"
                                          value={newCard.card_name}
                                          onChange={(e) =>
                                            newCreditcardHandleChange(e)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="single-input">
                                        <label htmlFor="cardHolder">
                                          Card Holder
                                        </label>
                                        <input
                                          type="text"
                                          name="card_holder_name"
                                          placeholder="Albert Einstein"
                                          autoComplete="off"
                                          value={newCard.card_holder_name}
                                          onChange={(e) =>
                                            newCreditcardHandleChange(e)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="single-input">
                                        <label htmlFor="cardNumber">
                                          Card Number
                                        </label>

                                        <CurrencyFormat
                                          type="text"
                                          name="card_bin"
                                          placeholder="0000-0000-0000-0000"
                                          autoComplete="off"
                                          value={newCard.card_bin}
                                          onChange={(e) =>
                                            newCreditcardHandleChange(e)
                                          }
                                          format="#### #### #### ####"
                                          mask="_"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="single-input">
                                        <label htmlFor="month">Month</label>
                                        <CurrencyFormat
                                          type="text"
                                          name="exp_month"
                                          placeholder={12}
                                          autoComplete="off"
                                          value={newCard.exp_month}
                                          onChange={(e) =>
                                            newCreditcardHandleChange(e)
                                          }
                                          format="##"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="single-input">
                                        <label htmlFor="year">Year</label>
                                        <CurrencyFormat
                                          type="text"
                                          name="exp_year"
                                          placeholder={30}
                                          autoComplete="off"
                                          value={newCard.exp_year}
                                          onChange={(e) =>
                                            newCreditcardHandleChange(e)
                                          }
                                          format="##"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="btn-border w-100">
                                        <button className="cmn-btn w-100">
                                          Add Card
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dashboard Section end */}

      {/* Transactions Popup start */}

      <div className="transactions-popup">
        <div className="container-fruid">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="modal fade"
                id="transactionsMod"
                aria-hidden="true"
              >
                <TransactionDetail
                  isOpen={isTransactionDetailOpen}
                  toggleModal={toggleTransactionModal}
                  closeOnOutsideClick={false}
                  transactionData={transactionData}
                ></TransactionDetail>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transactions Popup start */}

      <CSSTransition in={errorIsOpen} timeout={300} classNames="dialog">
        <Modal
          closeTimeoutMS={500}
          isOpen={errorIsOpen}
          style={errorModalStyles}
          className={
            (modalIsError ? "error-modal" : "success-modal") +
            " modal-dialog modal-dialog-centered transfer-popup"
          }
          onClick={() => toggleErrorModal("")}
        >
          <div className="modal-content">
            <div className="modal-header mb-60 justify-content-between">
              <button
                type="button"
                onClick={() => toggleErrorModal("")}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="main-content text-center">
              {modalIsError ? (
                <>
                  <img src="assets/images/icon/error.png" alt="icon" />
                  <h3>Error</h3>
                </>
              ) : (
                <>
                  <img src="assets/images/icon/success.png" alt="icon" />
                  <h3>Success</h3>
                </>
              )}

              <p>{errorMessage}</p>
            </div>
          </div>
        </Modal>
      </CSSTransition>

      {/* Confirm Popup start  */}

      <div class="transfer-popup">
        <div class="container-fruid">
          <div class="row">
            <div class="col-lg-6">
              <div class="modal fade" id="transferMod" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="transfer"
                      role="tabpanel"
                      aria-labelledby="transfer-tab"
                    >
                      <div class="modal-content">
                        <div class="modal-header  justify-content-between">
                          <button
                            id="btn-closes"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeModalButtonRef}
                          ></button>

                          <button
                            ref={openModalButtonRef}
                            style={{ display: "none" }}
                            data-bs-toggle="modal"
                            data-bs-target="#transferMod"
                          />
                         
                        </div>
                        <div class="main-content p-5">
                          <h4>Confirm Transfer!</h4>
                          <p>
                            We have sent a verification code on your registered phone
                            Please enter verification code below
                          </p>
                          <form onSubmit={confirmSubmit}>
                            <div class="userInput">
                              <input
                                type="text"
                                placeholder="SMS Code"
                                ref={otpRef}
                                autoComplete="off"
                                onChange={(e) => setOtpkey(e.target.value)}
                                value={otpkey}
                                required
                              />
                            </div>
                            <a href="javascript:void(0)">
                              Don’t receive a code?
                            </a>
                            <br />
                            <div className="col-12">
                              <div className="btn-border w-100">
                                <button className="cmn-btn w-100">
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm Popup start */}
    </>
  );
};

export default AddCard;
