import { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';


const Register = () => {
    const defaultUserModel = {
        name: "",
        surname: "",
        password: "",
        password2:""
    }

    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState(defaultUserModel)
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    const infoChangeHandle = (e) => {
        const value = e.target.value;
        setUserInfo({
            ...userInfo,
            [e.target.name]: value
        })
    }

    const confirmSubmit = async (e) => {
        e.preventDefault();

        if (userInfo.password != userInfo.password2) {
            setErrMsg("Password and Confirm Password didn't match");
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userInfo)
        };
        setLoading(true);
        fetch('api/auth/register', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            setSuccessMsg("Account created successfully. Please wait...")
                            setTimeout(() => {
                                navigate("/");
                            }, 1500);
                        } else {
                            setErrMsg(x.message)
                        }
                    });
                } else {
                    response.body.getReader().read().then(data => {
                        const str = String.fromCharCode.apply(String, data.value);
                        setErrMsg(JSON.parse(str).message);
                        if (JSON.parse(str).statusCode == 401) {
                            navigate("/");
                        }
                    })
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            {loading ?
                <div className="preloader"></div> :
                null
            }
            <section className="log-reg login-2">
                <div className="overlay">
                    <div className="container">
                        <div className="top-head-area">
                            <div className="row d-flex align-items-center">
                                <div className="col-sm-5 col">
                                    <a className="back-home" href="index.html">
                                        <img src="auth-assets/img/left-icon.png" alt="image" />
                                        Back To Parapay
                                    </a>
                                </div>
                                <div className="col-sm-5 col">
                                    <a href="https://www.parapay.uz">
                                        <img style={{width:"250px"}} src="auth-assets/img/parapay/Parapay_blue_bg.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <div className="img-area">
                                    <img src="auth-assets/img/login-bg-2.png" alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120">
                                <div className="form-box" style={{ width: '100%' }}>
                                    <h4>Confirm your Phonenumber</h4>
                                    <p className={successMsg ? "alert alert-success" : "offscreen"} aria-live="assertive">{successMsg}</p>
                                    <p className={errMsg ? "alert alert-danger" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab">
                                            <form onSubmit={confirmSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                placeholder="Firstname"
                                                                autoComplete="off"
                                                                onChange={(e) => infoChangeHandle(e)}
                                                                value={userInfo.name}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                name="surname"
                                                                placeholder="Surname"
                                                                autoComplete="off"
                                                                onChange={(e) => infoChangeHandle(e)}
                                                                value={userInfo.surname}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                placeholder="Password"
                                                                autoComplete="off"
                                                                onChange={(e) => infoChangeHandle(e)}
                                                                value={userInfo.password}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="password"
                                                                name="password2"
                                                                placeholder="Confirm Password"
                                                                autoComplete="off"
                                                                onChange={(e) => infoChangeHandle(e)}
                                                                value={userInfo.password2}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-area">
                                                    <button className="cmn-btn">Confirm</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Register;