import { useEffect, useRef, useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "./Card";

const PersonalWithdraw = () => {
  Modal.setAppElement("body");

  const defaultCommission = {
    fixedAmount: 0.0,
    rateAmount: 0.0,
    totalAmount: 0.0,
    feeType: 1,
    receiverFixedAmount: 0.0,
    receiverRateAmount: 0.0,
    receiverTotalAmount: 0.0,
  };
  const navigate = useNavigate();

  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [modalIsError, setModalIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [transferStep, setTransferStep] = useState(1);
  const [transferAmount, setTransferAmount] = useState(0.0);
  const [masterBalance, setMasterBalance] = useState(0.0);
  const [currency] = useState("UZS");
  const { auth } = useAuth();
  const [commission, setCommission] = useState(defaultCommission);

  const errorModalStyles = {
    overlay: {
      backgroundColor: "#00000070",
    },
  };

  const toggleErrorModal = (msg) => {
    setErrorMessage(msg);
    setErrorIsOpen(!errorIsOpen);
  };

  const [loading, setLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});

  const defaultNewCard = {
    card_bin: "",
    card_holder_name: "",
    card_name: "",
    exp_month: "",
    exp_year: "",
  };
  const [newCard, setNewCard] = useState(defaultNewCard);

  const getCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setCardList(x.data);
            localStorage.setItem("cards", JSON.stringify(x.data));
          }
        });
      }
    });
  };

  useEffect(() => {
    const cards = JSON.parse(localStorage.getItem("cards"));
    if (cards) {
      setCardList(cards);
    }
  }, []);

  const gotoStep2 = () => {
    if (Object.keys(selectedCard).length == 0) {
      setModalIsError(true);
      toggleErrorModal("Please Select a Card");
    } else {
      setTransferStep(2);
    }
  };

  const gotoStep3 = () => {
    if (transferAmount == 0) {
      setModalIsError(true);
      toggleErrorModal("Please Enter Amount");
    } else {
      setTransferStep(3);
      calculateCommission();
    }
  };

  const backtoStep1 = () => {
    setTransferStep(1);
    setTransferAmount(0.0);
    setSelectedCard({});
  };

  const backtoStep2 = () => {
    setTransferStep(2);
    setTransferAmount(0.0);
  };

  const newCreditcardHandleChange = (e) => {
    const value = e.target.value;
    setNewCard({
      ...newCard,
      [e.target.name]: value,
    });
  };

  const calculateCommission = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: transferAmount, currency }),
    };

    fetch("api/transaction/calculatewithdrawalcommission", requestOptions).then(
      (response) => {
        if (response.ok) {
          response.json().then((x) => {
            if (x.status) {
              setCommission(x.data);
            }
          });
        }
      }
    );
  };

  const addCreditCardSubmit = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newCard),
    };
    setLoading(true);
    fetch("api/card", requestOptions)
      .then((response) => {
        if (response.ok) {
          response.json().then((x) => {
            if (x.status) {
              setModalIsError(false);
              toggleErrorModal("Card successfully added");
              getCardList();
            } else {
              setModalIsError(true);
              toggleErrorModal(x.message);
            }
          });
        } else {
          toggleErrorModal("Server error");
        }
      })
      .finally(() => {
        setNewCard(defaultNewCard);
        setLoading(false);
      });
  };

  const confirmWithdraw = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cardToken: selectedCard.card_token,
        cardName: selectedCard.card_name,
        amount: transferAmount,
        currency,
      }),
    };

    setLoading(true);
    try {
      var response = await fetch("api/transaction/withdrawal", requestOptions);
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setModalIsError(false);
            toggleErrorModal("Withdrawal was successful");
            setTimeout(() => {
              navigate("/personal");
            }, 1000);
          } else {
            setModalIsError(true);
            toggleErrorModal(x.message);
          }
        });
      } else {
        const data = (await response.body.getReader().read()).value;
        const str = String.fromCharCode.apply(String, data);
        setModalIsError(true);
        toggleErrorModal(JSON.parse(str).message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMasterBalance(
      auth.user.wallets
        .filter((x) => x.currency == currency)
        .reduce(
          (a, v) =>
            (a = a + v.cash_available_balance + v.credit_available_balance),
          0
        )
    );
    getCardList();
  }, []);

  const cardRefs = useRef([]);
  const setSelectedStyle = (selectedIndex) => {
    for (let index = 0; index < cardRefs.current.length; index++) {
      if (cardRefs.current[index]) {
        cardRefs.current[index].classList.remove("checked");
        cardRefs.current[index].classList.remove("active-background");
      }
    }
    cardRefs.current[selectedIndex].classList.add("checked");
     cardRefs.current[selectedIndex].classList.add("active-background");
    
  };
  return (
    <>
      {loading ? <div className="preloader"></div> : null}
      <section
        className={
          (transferStep == 3 ? "step-3 " : "") +
          "dashboard-section body-collapse pay step crypto deposit-money"
        }
      >
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="head-area d-flex align-items-center justify-content-between">
                <h4>Withdraw Funds</h4>
                <div className="icon-area">
                  <img src="assets/images/icon/support-icon.png" alt="icon" />
                </div>
              </div>
              <div className="row justify-content-between pb-120">
                <div className="col-xl-3 col-lg-4 col-md-5">
                  <div className="left-area">
                    <ul>
                      <li>
                        <a
                          className={
                            (transferStep > 0 ? "active " : "") +
                            "single-link first"
                          }
                        >
                          Choose Recipient
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            (transferStep > 1 ? "active " : "") +
                            "single-link two"
                          }
                        >
                          Enter amount
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            (transferStep > 2 ? "active " : "") +
                            "single-link three last"
                          }
                        >
                          Confirm Withdrawal
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {transferStep == 1 ? (
                  <div className="col-xl-9 col-lg-8 col-md-7">
                    <div className="table-area">
                      <div className="head-area">
                        <h4>Linked Payment system</h4>
                      </div>
                      <div className="card-area d-flex flex-wrap">
                        {/* {
                                                        cardList ?
                                                            cardList.map((card) => (
                                                                <div className="sigle-card" key={card.card_token}>
                                                                    <input type="radio" name="cardSelect" id={card.card_token} />
                                                                    <label htmlFor={card.card_token} onClick={(() => setSelectedCard(card))}>
                                                                        <span className="wrapper" />
                                                                        <div className="cc-card-item linked" style={{ width: "250px" }}>
                                                                            <span className="bankname">{card.card_bank_name}</span>
                                                                            <span className="cardname">{card.card_name}</span>
                                                                            <span className="cardnumber">{card.card_bin_first_six.substring(0, 4) + ' ' + card.card_bin_first_six.substring(4, 6) + '** **** ' + card.card_bin_last_four}</span>
                                                                            <span className="cardexpire">{card.exp_month} / {card.exp_year}</span>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            ))
                                                            : null
                                                    } */}
                        {cardList
                          ? cardList.map((card, index) => (
                              <button
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                                ref={(ref) => {
                                  cardRefs.current[index + 2] = ref;
                                }}
                                className="cc-card-item parapay"
                                key={card.card_token}
                                onClick={() => {
                                  setSelectedCard(card);
                                  setSelectedStyle(index + 2);
                                }}
                              >
                                <Card cardLists={cardList} />
                              </button>
                            ))
                          : null}
                        <Link to="/personal/add-card">
                          <div
                            className="cc-card-item addnew"
                            style={{ width: "310px" }}
                            data-bs-toggle="modal"
                            data-bs-target="#addcardMod"
                          >
                            <label>+</label>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="footer-area mt-40">
                      <a onClick={() => gotoStep2()} className="active">
                        Next
                      </a>
                    </div>
                  </div>
                ) : transferStep == 2 ? (
                  <div className="col-xl-9 col-lg-8 col-md-7">
                    <div className="table-area">
                      <div className="form">
                        <div className="send-banance">
                          <span className="mdr">
                            How much you want to draw?
                          </span>
                          <div className="input-area">
                            <CurrencyFormat
                              placeholder="UZS1,000"
                              className="xxlr"
                              thousandSeparator={true}
                              prefix="UZS"
                              allowNegative={false}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              onValueChange={(values) => {
                                setTransferAmount(values.floatValue);
                              }}
                              isAllowed={(inpVal) =>
                                inpVal.floatValue
                                  ? inpVal.floatValue <= masterBalance
                                  : true
                              }
                            />
                          </div>
                          <p>
                            Available Balance
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={masterBalance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix="UZS"
                              renderText={(value) => <b>{value}</b>}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="footer-area mt-40">
                      <a onClick={() => backtoStep1()}>Previous Step</a>
                      <a onClick={() => gotoStep3()} className="active">
                        Next
                      </a>
                    </div>
                  </div>
                ) : transferStep == 3 ? (
                  <div className="col-xl-9 col-lg-8 col-md-7">
                    <form action="#">
                      <div className="payment-details">
                        <div className="top-area">
                          <h6>Confirm account &amp; amount</h6>
                        </div>
                        <div className="row">
                          <div className="col-xxl-8 col-xl-9 col-lg-12">
                            <ul className="details-list">
                              <li>
                                <span>Card Name</span>
                                <b>{selectedCard.card_name}</b>
                              </li>
                              <li>
                                <span>Payment Card</span>
                                <b>
                                  {selectedCard.card_bin_first_six.substring(
                                    0,
                                    4
                                  ) +
                                    " " +
                                    selectedCard.card_bin_first_six.substring(
                                      4,
                                      6
                                    ) +
                                    "** **** " +
                                    selectedCard.card_bin_last_four}
                                </b>
                              </li>
                              <li>
                                <span>You will draw</span>
                                <CurrencyFormat
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  value={
                                    commission.feeType == 1
                                      ? transferAmount
                                      : transferAmount - commission.totalAmount
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix="UZS"
                                  renderText={(value) => <b>{value}</b>}
                                />
                              </li>
                              <li>
                                <span>Fee</span>
                                {commission.totalAmount == 0 ? (
                                  <b>Free</b>
                                ) : (
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={commission.totalAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix="UZS"
                                    renderText={(value) => <b>{value}</b>}
                                  />
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="checkbox-area mt-40 d-flex align-items-center justify-content-center">
                        <input type="checkbox" id="accept" name="accept" />
                        <label htmlFor="accept">
                          I accept <a>terms of use</a>
                        </label>
                      </div>
                      <div className="footer-area mt-40">
                        <a onClick={() => backtoStep2()}>Previous Step</a>
                        <a onClick={() => confirmWithdraw()} className="active">
                          Withdraw Money
                        </a>
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add Card Popup start */}
      {/* <div className="add-card">
                <div className="container-fruid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="addcardMod" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-between">
                                            <h6>Add Card</h6>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                                        </div>
                                        <form onSubmit={addCreditCardSubmit}>
                                            <div className="row justify-content-center">
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardNumber">Card Name</label>
                                                        <input type="text"
                                                            name="card_name"
                                                            placeholder="My Card"
                                                            autoComplete="off"
                                                            value={newCard.card_name}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardNumber">Card Number</label>

                                                        <CurrencyFormat type="text"
                                                            name="card_bin"
                                                            placeholder="0000-0000-0000-0000"
                                                            autoComplete="off"
                                                            value={newCard.card_bin}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="#### #### #### ####"
                                                            mask="_"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardHolder">Card Holder</label>
                                                        <input type="text"
                                                            name="card_holder_name"
                                                            placeholder="Albert Einstein"
                                                            autoComplete="off"
                                                            value={newCard.card_holder_name}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="single-input">
                                                        <label htmlFor="month">Month</label>
                                                        <CurrencyFormat type="text"
                                                            name="exp_month"
                                                            placeholder={12}
                                                            autoComplete="off"
                                                            value={newCard.exp_month}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="##"
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="single-input">
                                                        <label htmlFor="year">Year</label>
                                                        <CurrencyFormat type="text"
                                                            name="exp_year"
                                                            placeholder={30}
                                                            autoComplete="off"
                                                            value={newCard.exp_year}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="##"
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="btn-border w-100">
                                                        <button className="cmn-btn w-100">Add Card</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* Add Card Popup start */}

      <CSSTransition in={errorIsOpen} timeout={300} classNames="dialog">
        <Modal
          closeTimeoutMS={500}
          isOpen={errorIsOpen}
          style={errorModalStyles}
          className={
            (modalIsError ? "error-modal" : "success-modal") +
            " modal-dialog modal-dialog-centered transfer-popup"
          }
          onClick={() => toggleErrorModal("")}
        >
          <div className="modal-content">
            <div className="modal-header mb-60 justify-content-between">
              <button
                type="button"
                onClick={() => toggleErrorModal("")}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="main-content text-center">
              {modalIsError ? (
                <>
                  <img src="assets/images/icon/error.png" alt="icon" />
                  <h3>Error</h3>
                </>
              ) : (
                <>
                  <img src="assets/images/icon/success.png" alt="icon" />
                  <h3>Success</h3>
                </>
              )}

              <p>{errorMessage}</p>
            </div>
          </div>
        </Modal>
      </CSSTransition>
    </>
  );
};

export default PersonalWithdraw;
