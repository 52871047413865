import { useEffect, useRef, useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";

const Favorites = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  return (
    <>
      <section className="dashboard-section body-collapse pay step crypto deposit-money">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-between pb-120">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="table-area">
                    <div className="head-area">
                      <h5>
                        <b>Favorite</b>
                        <Link to="#favorite">
                          <small>
                            {" "}
                            &nbsp; <u>All Favorite</u>
                          </small>
                        </Link>
                      </h5>
                    </div>

                    <div class="row pb-120">
                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>

                      <div class="col-lg-3 col-md-5">
                        <a href="javascript:void(0)" class="single-item">
                          <div class="icon-area">
                            <img
                              src="assets/images/icon/zyro.png"
                              alt="icon"
                            />
                            <span>Lorem Impsum</span>
                          </div>
                        </a>
                      </div>
                    </div>

                    <Link to="#home">
                      <button className="btn" style={{ backgroundColor: "#673AB7" }}>
                        <i class="icon-e-plus"></i>

                        <span>
                          &nbsp; <b style={{ color: "white" }}>New Home</b>
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Favorites;
