import { Component } from 'react';
import { Outlet } from 'react-router-dom';
import useImportScript from '../../hooks/useImportScript'


const AuthLayout = () => {

    useImportScript("auth-assets/js/jquery.min.js");
    useImportScript("auth-assets/js/proper-min.js");
    useImportScript("auth-assets/js/bootstrap.min.js");
    useImportScript("auth-assets/js/fontawesome.js");
    useImportScript("auth-assets/js/plugin/slick.js");
    useImportScript("auth-assets/js/plugin/jquery.magnific-popup.min.js");
    useImportScript("auth-assets/js/plugin/apexcharts.min.js");
    useImportScript("auth-assets/js/plugin/jquery.nice-select.min.js");
    useImportScript("auth-assets/js/plugin/jquery.countdown.js");
    useImportScript("auth-assets/js/plugin/wow.min.js");
    useImportScript("auth-assets/js/plugin/plugin.js");
    useImportScript("auth-assets/js/main.js");

    return (
        <>
            <link rel="stylesheet" href="auth-assets/css/bootstrap.min.css" />
            <link rel="stylesheet" href="auth-assets/css/fontawesome.min.css" />
            <link rel="stylesheet" href="auth-assets/css/plugin/nice-select.css" />
            <link rel="stylesheet" href="auth-assets/css/plugin/slick.css" />
            <link rel="stylesheet" href="auth-assets/css/custom-family.css" />
            <link rel="stylesheet" href="auth-assets/css/plugin/magnific-popup.css" />
            <link rel="stylesheet" href="auth-assets/css/plugin/animate.css" />
            <link rel="stylesheet" href="auth-assets/css/style.css" />

            <div className="preloader" id="preloader"></div>

            <a href="#" className="scrollToTop"><i className="fas fa-angle-double-up"></i></a>
            <Outlet />

        </>
    );
}

export default AuthLayout;