import { useRef, useState, useEffect } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../hooks/useAuth";
import Modal from "react-modal";
import Post from "./Post";
import "./PayingBills.css";
import { CCollapse, CButton, CCard, CCardBody } from "@coreui/react";
import { Card } from "../Personal/Card";

const Reports = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  const [visible, setVisible] = useState(false);
  const [pageN, setPageN] = useState(2);
  const [transform, setTransform] = useState(0);
  const [currency, setCurrency] = useState("UZS");
  const [cardList, setCardList] = useState([]);
  const [cardReportList, setCardReportList] = useState([]);
  const [cardToken, setCardToken] = useState([]);

  const getCardReportList = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cardToken,
        lastId: 0,
        pageSize: pageN,
      }),
    };
    fetch("api/transaction/query/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            if (x.data.length) {
              setCardReportList([...cardReportList, ...x.data]);
            }
          }
        });
      }
    });
  };
  useEffect(() => {
    const cards = JSON.parse(localStorage.getItem("cards"));
    if (cards) {
      setCardList(cards);
    }
  }, []);

  const getCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setCardList(x.data);
            localStorage.setItem("cards", JSON.stringify(x.data));
          }
        });
      }
    });
  };

  useEffect(() => {
    getCardReportList();
    getCardList();
  }, []);

  const handleLoading = () => {
    getCardReportList();
    setPageN(pageN + 3);
  };


  const getDetail = (token) => {
    setCardToken(token);
    getCardReportList();
   
  };

  const cardRefs = useRef([]);
  const setSelectedStyle = (selectedIndex) => {
    for (let index = 0; index < cardRefs.current.length; index++) {
      if (cardRefs.current[index]) {
        cardRefs.current[index].classList.remove("checked");
        cardRefs.current[index].classList.remove("active-background");
      }
    }
    cardRefs.current[selectedIndex].classList.add("checked");
     cardRefs.current[selectedIndex].classList.add("active-background");
     cardRefs.current[selectedIndex].classList.remove("parapay");
  };


  return (
    <>
      <section className="dashboard-section body-collapse pay step crypto deposit-money">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-between pb-120">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="table-area">
                    <div className="head-area">
                      <h5>
                        <b>Payment Reports</b>
                      </h5>
                    </div>

                    {/* begin::collapse */}
                    <button
                      className="w-100 btn btn-light border rounded"
                      onClick={(event) => {
                        event.preventDefault();
                        setVisible(!visible);
                        transform == 90 ? setTransform(0) : setTransform(90);
                      }}
                    >
                      <b>Card List </b>{" "}
                      <img
                        className="p-2"
                        style={{
                          float: "right",
                          transform: `rotate(${transform}deg)`,
                        }}
                        src="assets/images/icon/right-arrow.png"
                      />
                    </button>

                    <CCollapse visible={visible}>
                      <CCard className="mt-3">
                        <CCardBody>
                          {cardList
                            ? cardList.map((card,index) => (
                                <button
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    backgroundSize: "cover",
                                  }}
                                  className="cc-card-item parapay"
                                  key={card.card_token}
                                  ref={(ref) => {
                                    cardRefs.current[index + 2] = ref;
                                  }}
                                  onClick={() => {
                                    getDetail([...cardToken,...card.card_token]);
                                    setSelectedStyle(index + 2);
                                  }}
                                >
                                  <Card cardLists={cardList} />
                                </button>
                              ))
                            : null}
                        </CCardBody>
                      </CCard>
                    </CCollapse>
                    {/* end::collapse */}

                    <div className="table-responsive rounded border mt-5">
                      <table className="table   p-2">
                        <thead>
                          <tr>
                            <th scope="col">Source</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <Post cardReportLists={cardReportList} />
                        </tbody>
                      </table>

                      {pageN >= cardReportList.length ? (
                        <div>
                          <button
                            className="btn btn-outline-secondary text-center   w-100 "
                            onClick={() => handleLoading()}
                          >
                            Load more...
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reports;
