const MAX_Y_CONTROL_POINT_SHIFT = 50
const MAX_X_CONTROL_POINT_SHIFT = 10

export const calculateLowDyControlPointShift = (
  dx,
  dy,
  maxShift = MAX_Y_CONTROL_POINT_SHIFT
) => {
  if (dx > 0) return 0
  const sign = dy < 0 ? -1 : 1
  const value = Math.round(
    maxShift * Math.pow(0.9, Math.pow(1.2, Math.abs(dy) / 10))
  )

  // prevent negative zero
  if (value === 0) return 0

  return sign * value
}

export const calculateDeltas = (startPoint, endPoint) => {
  const dx = endPoint.x - startPoint.x
  const dy = endPoint.y - startPoint.y
  const absDx = Math.abs(dx)
  const absDy = Math.abs(dy)

  return { dx, dy, absDx, absDy }
}

export const calculateCanvasDimensions = ({
  absDx,
  absDy,
  boundingBoxBuffer
}) => {
  const canvasWidth = absDx + 2 * boundingBoxBuffer.horizontal
  const canvasHeight = absDy + 2 * boundingBoxBuffer.vertical

  return { canvasWidth, canvasHeight }
}

// Curve flexure should remain on the same area no matter of absolute deltas, so we have to slightly shift X coordinates of our control points. It was created empirically, it's not based on a clear formula.
export const calculateFixedLineInflectionConstant = (absDx, absDy) => {
  const WEIGHT_X = 10
  const WEIGHT_Y = 2.8

  return Math.round(Math.sqrt(absDx) * WEIGHT_X + Math.sqrt(absDy) * WEIGHT_Y)
}

export const calculateControlPointsWithoutBoundingBox = ({
  absDx,
  absDy,
  dx,
  dy
}) => {
  let leftTopX = 0
  let leftTopY = 0
  let rightBottomX = absDx
  let rightBottomY = absDy
  if (dx < 0) [leftTopX, rightBottomX] = [rightBottomX, leftTopX]
  if (dy < 0) [leftTopY, rightBottomY] = [rightBottomY, leftTopY]

  const fixedLineInflectionConstant = calculateFixedLineInflectionConstant(
    absDx,
    absDy
  )
  const lowDyYShift = calculateLowDyControlPointShift(dx, dy)
  const lowDyXShift = Math.abs(
    calculateLowDyControlPointShift(dx, dy, MAX_X_CONTROL_POINT_SHIFT)
  )

  const p1 = {
    x: leftTopX,
    y: leftTopY
  }
  const p2 = {
    x: leftTopX + fixedLineInflectionConstant + lowDyXShift,
    y: leftTopY + lowDyYShift
  }
  const p3 = {
    x: rightBottomX - fixedLineInflectionConstant - lowDyXShift,
    y: rightBottomY - lowDyYShift
  }
  const p4 = {
    x: rightBottomX,
    y: rightBottomY
  }

  return { p1, p2, p3, p4 }
}
export const calculateControlPoints = ({
  boundingBoxElementsBuffer,
  absDx,
  absDy,
  dx,
  dy
}) => {
  const { p1, p2, p3, p4 } = calculateControlPointsWithoutBoundingBox({
    absDx,
    absDy,
    dx,
    dy
  })

  const topBorder = Math.min(p1.y, p2.y, p3.y, p4.y)
  const bottomBorder = Math.max(p1.y, p2.y, p3.y, p4.y)
  const leftBorder = Math.min(p1.x, p2.x, p3.x, p4.x)
  const rightBorder = Math.max(p1.x, p2.x, p3.x, p4.x)

  const verticalBuffer =
    (bottomBorder - topBorder - absDy) / 2 + boundingBoxElementsBuffer
  const horizontalBuffer =
    (rightBorder - leftBorder - absDx) / 2 + boundingBoxElementsBuffer

  const boundingBoxBuffer = {
    vertical: verticalBuffer,
    horizontal: horizontalBuffer
  }

  return {
    p1: {
      x: p1.x + horizontalBuffer,
      y: p1.y + verticalBuffer
    },
    p2: {
      x: p2.x + horizontalBuffer,
      y: p2.y + verticalBuffer
    },
    p3: {
      x: p3.x + horizontalBuffer,
      y: p3.y + verticalBuffer
    },
    p4: {
      x: p4.x + horizontalBuffer,
      y: p4.y + verticalBuffer
    },
    boundingBoxBuffer
  }
}
