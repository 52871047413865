import React, { useEffect, useRef } from 'react';
import Moment from 'moment'

const TransactionDetail = ({ isOpen, toggleModal, closeOnOutsideClick, transactionData, children }) => {
    Moment.locale('en');
    const modalRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOnOutsideClick && modalRef.current && !modalRef.current.contains(event.target)) {
                toggleModal(null);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, closeOnOutsideClick, toggleModal, transactionData]);
    return (
        <>
            <div className="modal-dialog modal-dialog-centered" ref={modalRef}>
                {
                    transactionData ?
                        <div className="modal-content">
                            <div className="modal-header justify-content-between">
                                <h5>Transaction Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                            </div>
                            <div className="main-content">
                                <div className="row">
                                    <div className="col-sm-5 text-center">
                                        <div className="icon-area">
                                            <img src="assets/images/icon/transaction-details-icon.png" alt="icon" />
                                        </div>
                                        <div className="text-area">
                                            <h6>{JSON.parse(transactionData.json_data).other_side_title}</h6>
                                            <p>{Moment(transactionData.insert_date).format('DD MMM yyyy HH:mm:ss')}</p>
                                            <h3>{transactionData.base_amount - transactionData.fee_amount} {transactionData.currency}</h3>
                                            <p className={
                                                transactionData.financial_process_status == 1 || transactionData.financial_process_status == 11 ?
                                                    "trx-pending" :
                                                    transactionData.financial_process_status == 2 ? "trx-inprogress" :
                                                        transactionData.financial_process_status == 3 || transactionData.financial_process_status == 5 ? "trx-completed" :
                                                            transactionData.financial_process_status == 4 ? "trx-cancelled" : ""


                                            }>
                                                {
                                                    transactionData.financial_process_status == 1 || transactionData.financial_process_status == 11 ?
                                                        "Pending" :
                                                        transactionData.financial_process_status == 2 ? "In Progress" :
                                                            transactionData.financial_process_status == 3 || transactionData.financial_process_status == 5 ? "Completed" :
                                                                transactionData.financial_process_status == 4 ? "Cancelled" : "Not Known"


                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="right-area">
                                            <h6>Transaction Details</h6>
                                            <ul className="payment-details">
                                                <li>
                                                    <span>Base Amount</span>
                                                    <span>{transactionData.base_amount} {transactionData.currency}</span>
                                                </li>
                                                <li>
                                                    <span>Fee</span>
                                                    <span>{transactionData.fee_amount} {transactionData.currency}</span>
                                                </li>
                                                <li>
                                                    <span>Net Amount</span>
                                                    <span>{transactionData.base_amount - transactionData.fee_amount} {transactionData.currency}</span>
                                                </li>
                                            </ul>
                                            <ul className="payment-info">
                                                <li>
                                                    <p>Payment From/To</p>
                                                    <span className="mdr">{JSON.parse(transactionData.json_data).other_side_title}</span>
                                                </li>
                                                <li>
                                                    <p>Payment Description</p>
                                                    <span className="mdr">{transactionData.json_data.description}</span>
                                                </li>
                                                <li>
                                                    <p>Transaction  ID:</p>
                                                    <span className="mdr">{transactionData.tx_group_relation_id}-{transactionData.deposit_flag}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>
    )
}
export default TransactionDetail;