import { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {

    const otpRef = useRef();
    const errRef = useRef();

    const [resetkey, setResetKey] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        otpRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [resetkey]);

    const confirmSubmit = async (e) => {
        // e.preventDefault();

        // const requestOptions = {
        //     method: 'GET'
        // };
        // var resetKeyVal = await resetkey;
        // setLoading(true);
        // fetch('api/auth/otp-confirm/' + resetKeyVal, requestOptions)
        //     .then(response => {
        //         if (response.ok) {
        //             response.json().then(x => {
        //                 if (x.status) {
        //                     navigate("/auth/" + x.data);
        //                 } else {
        //                     setErrMsg(x.message)
        //                 }
        //             });
        //         } else {
        //             response.body.getReader().read().then(data => {
        //                 const str = String.fromCharCode.apply(String, data.value);
        //                 setErrMsg(JSON.parse(str).message);
        //                 if (JSON.parse(str).statusCode == 401) {
        //                     navigate("/");
        //                 }
        //             })
        //         }
        //     })
        //     .finally(() => {
        //         setResetKey('');
        //         setLoading(false);
        //     })
    }

    return (
        <>
            {loading ?
                <div className="preloader"></div> :
                null
            }
            <section className="log-reg login-2">
                <div className="overlay">
                    <div className="container">
                        <div className="top-head-area">
                            <div className="row d-flex align-items-center">
                                <div className="col-sm-5 col">
                                    <a className="back-home" href="index.html">
                                        <img src="auth-assets/img/left-icon.png" alt="image" />
                                        Back To Parapay
                                    </a>
                                </div>
                                <div className="col-sm-5 col">
                                    <a href="https://www.parapay.uz">
                                        <img style={{width:"250px"}} src="auth-assets/img/parapay/Parapay_blue_bg.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <div className="img-area">
                                    <img src="auth-assets/img/login-bg-2.png" alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120">
                                <div className="form-box" style={{ width: '100%' }}>
                                    <h4>Reset your Password</h4>
                                    <p ref={errRef} className={errMsg ? "alert alert-danger" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab">
                                            <form onSubmit={confirmSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                placeholder="SMS Code"
                                                                ref={otpRef}
                                                                autoComplete="off"
                                                                onChange={(e) => setResetKey(e.target.value)}
                                                                value={resetkey}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-area">
                                                <button className="cmn-btn mb-2">Send SMS Code +998******050</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ResetPassword;