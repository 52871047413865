import { useEffect, useRef, useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import useAuth from "../../../hooks/useAuth";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { NavLink, useNavigate } from "react-router-dom";
import { data } from "jquery";
import "../../PayingBills/PayingBills";
import { Card, CardParapay } from "../Card";

const TransferPersonal = () => {
  Moment.locale("en");
  Modal.setAppElement("body");

  const defaultCommission = {
    fixedAmount: 0.0,
    rateAmount: 0.0,
    totalAmount: 0.0,
    feeType: 1,
    receiverFixedAmount: 0.0,
    receiverRateAmount: 0.0,
    receiverTotalAmount: 0.0,
  };

  const [recipientList, setRecipientList] = useState([]);
  const [recipientSearchInput, setRecipientSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [transferStep, setTransferStep] = useState(1);
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const { auth } = useAuth();
  const [masterBalance, setMasterBalance] = useState(0.0);
  const [walletBalance, setWalletBalance] = useState(0.0);
  const [currency] = useState("UZS");
  const [transferAmount, setTransferAmount] = useState(0.0);
  const [commission, setCommission] = useState(defaultCommission);
  const [transferDescription, setTransferDescription] = useState("");
  const [senderCardList, setSenderCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [recipientDetail, setRecipientDetail] = useState({});

  const cardRefs = useRef([]);

  const navigate = useNavigate();
  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [modalIsError, setModalIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const errorModalStyles = {
    overlay: {
      backgroundColor: "#00000070",
    },
  };

  const getSenderCardList = async () => {
    const requestOptions = {
      method: "GET",
    };
    fetch("api/card", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setSenderCardList(x.data);
            localStorage.setItem("senderCards", JSON.stringify(x.data));
          }
        });
      }
    });
  };

  useEffect(() => {
    const senderCards = JSON.parse(localStorage.getItem("senderCards"));
    if (senderCards) {
      setSenderCardList(senderCards);
    }
  }, []);

  const getRecipients = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ text: recipientSearchInput }),
    };

    fetch("api/recipient/search", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setRecipientList(x.data);
          }
        });
      }
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (recipientSearchInput.length > 7) {
        getRecipients();
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [recipientSearchInput]);

  const chooseRecipient = (recipient) => {
    getSenderCardList();
    setSelectedRecipient(recipient);
    setTransferStep(2);
    setRecipientSearchInput("");
    setRecipientList([]);
    getRecipientDetail(recipient);
  };

  const getRecipientDetail = async (recipient) => {
    if (!recipient) return;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch("api/recipient/get/" + recipient.recipient_id, requestOptions).then(
      (response) => {
        if (response.ok) {
          response.json().then((x) => {
            if (x.status) {
              setRecipientDetail(x.data);
            }
          });
        }
      }
    );
  };

  const changeRecipient = () => {
    setTransferStep(1);
    setSelectedRecipient({});
    setTransferAmount(0.0);
    setCommission(defaultCommission);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          senderCard: selectedCard.card_token,
          recipientId: selectedRecipient.recipient_id,
          amount: transferAmount,
          currency,
        }),
      };

      fetch("api/transaction/calculatetransfercommission", requestOptions).then(
        (response) => {
          if (response.ok) {
            response.json().then((x) => {
              if (x.status) {
                setCommission(x.data);
              }
            });
          }
        }
      );
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [transferAmount]);

  useEffect(() => {
    setMasterBalance(selectedCard.balance);
  }, [selectedCard]);

  useEffect(() => {
    setWalletBalance(
      auth.user.wallets
        .filter((x) => x.currency == currency)
        .reduce(
          (a, v) =>
            (a = a + v.cash_available_balance + v.credit_available_balance),
          0
        )
    );
  }, []);

  const toggleErrorModal = (msg) => {
    setErrorMessage(msg);
    setErrorIsOpen(!errorIsOpen);
  };

  const confirmTransfer = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        description: transferDescription,
        senderCard: selectedCard.card_token,
        recipientId: selectedRecipient.recipient_id,
        amount: transferAmount,
        currency: currency,
      }),
    };

    setLoading(true);
    try {
      var response = await fetch("api/transaction/transfer", requestOptions);
      if (response.ok) {
        response.json().then((x) => {
          if (x.status) {
            setModalIsError(false);
            toggleErrorModal("Transfer was successful");
            setTimeout(() => {
              navigate("/personal");
            }, 1000);
          } else {
            setModalIsError(true);
            toggleErrorModal(x.message);
          }
        });
      } else {
        const data = (await response.body.getReader().read()).value;
        const str = String.fromCharCode.apply(String, data);
        setModalIsError(true);
        toggleErrorModal(JSON.parse(str).message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const setSelectedStyle = (selectedIndex) => {
    for (let index = 0; index < cardRefs.current.length; index++) {
      if (cardRefs.current[index]) {
        cardRefs.current[index].classList.remove("checked");
        cardRefs.current[index].classList.remove("active-background");
      }
    }
    cardRefs.current[selectedIndex].classList.add("checked");
     cardRefs.current[selectedIndex].classList.add("active-background");
    
  };

  return (
    <>
      {loading ? <div className="preloader"></div> : null}
      {transferStep == 1 ? (
        <>
          <section className="dashboard-section body-collapse pay step crypto deposit-money">
            <div className="overlay pt-120">
              <div className="container-fruid">
                <div className="main-content">
                  <div className="head-area d-flex align-items-center justify-content-between">
                    <h4>Transfers</h4>
                    <div className="icon-area border rounded border-info p-0">
                      <NavLink to="/personal/transfer">
                        <button
                          className="rounded border btn btn-outline-info btn-sm  active"
                          style={{ border: "none !import" }}
                        >
                          Transfer of funds
                        </button>
                      </NavLink>
                      <NavLink to="/personal/transfer-card">
                        <button
                          className="rounded border btn btn-outline-info btn-sm "
                          style={{ border: "none !import" }}
                        >
                          Between my cards
                        </button>
                      </NavLink>
                    </div>
                  </div>
                  <div className="row justify-content-between pb-120">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="table-area">
                        <form action="#" className="flex-fill">
                          <div className="form-group d-flex align-items-center">
                            <img
                              src="assets/images/icon/search.png"
                              alt="icon"
                            />
                            <input
                              type="text"
                              placeholder="Enter your phone or card number"
                              value={recipientSearchInput}
                              onChange={(e) =>
                                setRecipientSearchInput(e.target.value)
                              }
                            />
                          </div>
                        </form>

                        {recipientList.map((recipient) => {
                          return (
                            <>
                              <div className="user-select">
                                <div
                                  className="single-user border rounded p-3 mt-2 "
                                  style={{ backgroundColor: "#D3D3D3" }}
                                >
                                  <div className="left d-flex">
                                    <div
                                      className="img-area border rounded"
                                      style={{ width: "60px", height: "60px" }}
                                    >
                                      <img
                                        src={`assets/images/${
                                          recipient.wallet_flag == 1
                                            ? "logo.png"
                                            : "card.png"
                                        }`}
                                        alt="image"
                                      />
                                    </div>
                                    <label
                                      onClick={() => {
                                        chooseRecipient(recipient);
                                      }}
                                    >
                                      <div className="text-area">
                                        <p>
                                          <img
                                            src="assets/images/icon/person.png"
                                            alt="image"
                                          />{" "}
                                          {recipient.name}
                                        </p>
                                        <span className="mdr">
                                          {recipient.wallet_flag == 1 ? (
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              src="assets/images/icon/phone.png"
                                              alt="image"
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              src="assets/images/icon/cardp.png"
                                              alt="image"
                                            />
                                          )}
                                          {"  "}
                                          {recipient.masked_recipient}
                                        </span>
                                      </div>
                                    </label>
                                  </div>

                                  <div className="right">
                                    <a
                                      onClick={() => {
                                        chooseRecipient(recipient);
                                      }}
                                    >
                                      Choose
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : transferStep == 2 ? (
        <>
          <section className="dashboard-section body-collapse pay">
            <div className="overlay pt-120">
              <div className="container-fruid">
                <div className="main-content">
                  <div className="head-area d-flex align-items-center justify-content-between">
                    <h4>Make a transfer</h4>
                    <div className="icon-area">
                      <img
                        src="assets/images/icon/support-icon.png"
                        alt="icon"
                      />
                    </div>
                  </div>
                  {/* START CARD LIST */}
                  <div className="side-items">
                    <div className="single-item">
                      <div className="section-text d-flex align-items-center justify-content-between">
                        <h6>Linked Payment system</h6>
                      </div>

                      <div className="row">
                        {senderCardList
                          ? senderCardList.map((card, index) => (
                              <button
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                }}
                                ref={(ref) => {
                                  cardRefs.current[index + 2] = ref;
                                }}
                                className="cc-card-item parapay"
                                key={card.card_token}
                                onClick={() => {
                                  setSelectedCard(card);
                                  setSelectedStyle(index + 2);
                                }}
                              >
                                <Card cardLists={senderCardList} />
                              </button>
                            ))
                          : null}
                        <button
                          ref={(ref) => {
                            cardRefs.current[1] = ref;
                          }}
                          onClick={() => {
                            setSelectedCard({
                              card_token: currency,
                              balance: walletBalance,
                            });
                            setSelectedStyle(1);
                          }}
                          style={{
                            marginTop: "8px",
                            marginBottom: "8px",
                            backgroundSize: "cover",
                          }}
                          className="cc-card-item parapay"
                        >
                          <CardParapay
                            cardNumber={auth.user.account.account_number}
                            WalletBalance={walletBalance}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* END CARD LIST */}

                  <form className="border rounded p-2 mt-3">
                    <div className="send-banance">
                      <span className="mdr">
                        <b>You Send</b>
                      </span>
                      <br />
                      <span>
                        Amount with commission :{" "}
                        <CurrencyFormat
                          value={transferAmount + commission.totalAmount}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currency}
                          renderText={(value) => <b>{value}</b>}
                        />
                      </span>
                      <br />
                      <div className="mt-3 mb-3" style={{ border: "none" }}>
                        <CurrencyFormat
                          placeholder="UZS1,000"
                          className="xxlr"
                          thousandSeparator={true}
                          prefix="UZS"
                          allowNegative={false}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          onValueChange={(values) => {
                            setTransferAmount(values.floatValue);
                          }}
                          isAllowed={(inpVal) =>
                            inpVal.floatValue
                              ? inpVal.floatValue <= masterBalance
                              : true
                          }
                        />
                      </div>
                      <p>
                        Available Balance:{" "}
                        <CurrencyFormat
                          value={masterBalance}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currency}
                          renderText={(value) => <b>{value}</b>}
                        />
                      </p>
                    </div>

                    <div className="footer-area mt-40 mb-2">
                      <a
                        onClick={() => {
                          changeRecipient();
                        }}
                      >
                        Select another card
                      </a>
                      <a data-bs-toggle="modal" data-bs-target="#transferMod">
                        Transfer
                      </a>
                    </div>
                  </form>
                  {/*  Transfer Popup start  */}
                  <div className="transfer-popup">
                    <div className="container-fruid">
                      <div className="row">
                        <div className="col-lg-16">
                          <div
                            className="modal fade"
                            id="transferMod"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="tab-content  w-100 p-3">
                                <div
                                  className="tab-pane fade show active"
                                  id="transfer"
                                  role="tabpanel"
                                  aria-labelledby="transfer-tab "
                                >
                                  <div className="modal-content ">
                                    <div className="modal-header  justify-content-between">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>

                                    <div className="main-content">
                                      <div className="paymentDetails">
                                        <div className="row">
                                          <div className="col-xl-12">
                                            <ul>
                                              <li>
                                                <span>You Send: </span>{" "}
                                                <CurrencyFormat
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  value={transferAmount}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix="UZS"
                                                  renderText={(value) => (
                                                    <b>{value}</b>
                                                  )}
                                                />
                                              </li>
                                              <li>
                                                <span>Fee: </span>{" "}
                                                {commission.totalAmount == 0 ? (
                                                  <b>Free</b>
                                                ) : (
                                                  <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                      commission.totalAmount
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix="UZS"
                                                    renderText={(value) => (
                                                      <b>{value}</b>
                                                    )}
                                                  />
                                                )}
                                              </li>
                                              {commission.receiverTotalAmount >
                                              0 ? (
                                                <li>
                                                  <span>Recipient fee: </span>{" "}
                                                  <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                      commission.receiverTotalAmount
                                                    }
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix="UZS"
                                                    renderText={(value) => (
                                                      <b>{value}</b>
                                                    )}
                                                  />
                                                </li>
                                              ) : null}
                                              <li>
                                                <span>Recipient gets: </span>{" "}
                                                <CurrencyFormat
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  value={
                                                    transferAmount -
                                                    commission.receiverTotalAmount
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix="UZS"
                                                  renderText={(value) => (
                                                    <b>{value}</b>
                                                  )}
                                                />
                                              </li>
                                              <li>
                                                <span>Receiver Name: </span>{" "}
                                                <b>{selectedRecipient.name}</b>
                                              </li>
                                              <li>
                                                <span>Receiver: </span>{" "}
                                                <b>
                                                  {
                                                    selectedRecipient.masked_recipient
                                                  }
                                                </b>
                                              </li>
                                              <li>
                                                <span>Description: </span>{" "}
                                                <input
                                                  type="text"
                                                  value={transferDescription}
                                                  onChange={(e) =>
                                                    setTransferDescription(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                />
                                              </li>
                                              <li>
                                                <span>
                                                  Transfer will be receive on:{" "}
                                                </span>{" "}
                                                <b>
                                                  {Moment().format(
                                                    "DD MMM yyyy HH:mm"
                                                  )}{" "}
                                                </b>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <form className="form">
                                        <div className="checkbox">
                                          <input
                                            type="checkbox"
                                            id="confirm"
                                            required="required"
                                          />{" "}
                                          <label htmlFor="confirm">
                                            I confirm the payment details above
                                          </label>
                                        </div>
                                        <div className="col-12">
                                          <div className="btn-border w-100">
                                            <a
                                              className="cmn-btn w-100"
                                              onClick={() => confirmTransfer()}
                                            >
                                              Confirm
                                            </a>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  Transfer Popup start  */}
                  <div className="table-responsive rounded border mt-5">
                    <table className="table   p-2">
                      <thead>
                        <tr>
                          <th scope="col">Source</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recipientDetail && recipientDetail.history
                          ? recipientDetail.history.map((history) => (
                              <tr>
                                <th scope="row">
                                  <p>
                                    <b>
                                      {history.from_wallet_flag
                                        ? "wallet"
                                        : history.card_masked}
                                    </b>
                                  </p>
                                </th>
                                <td>
                                  <p>
                                    <b>
                                      {Moment(history.insert_date).format(
                                        "HH:mm"
                                      )}
                                    </b>
                                  </p>
                                  <p className="mdr">
                                    {Moment(history.insert_date).format(
                                      "DD MMM yyyy"
                                    )}
                                  </p>
                                </td>
                                <td>
                                  <p className="trx-completed">
                                    <b>Completed</b>
                                  </p>
                                </td>
                                <td>
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={history.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={
                                      (history.deposit_flag == 1 ? "+" : "-") +
                                      history.currency
                                    }
                                    renderText={(value) => (
                                      <p
                                        className="text-danger"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {value}
                                      </p>
                                    )}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}

      <CSSTransition in={errorIsOpen} timeout={300} classNames="dialog">
        <Modal
          closeTimeoutMS={500}
          isOpen={errorIsOpen}
          style={errorModalStyles}
          className={
            (modalIsError ? "error-modal" : "success-modal") +
            " modal-dialog modal-dialog-centered transfer-popup"
          }
          onClick={() => toggleErrorModal("")}
        >
          <div className="modal-content">
            <div className="modal-header mb-60 justify-content-between">
              <button
                type="button"
                onClick={() => toggleErrorModal("")}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="main-content text-center">
              {modalIsError ? (
                <>
                  <img src="assets/images/icon/error.png" alt="icon" />
                  <h3>Error</h3>
                </>
              ) : (
                <>
                  <img src="assets/images/icon/success.png" alt="icon" />
                  <h3>Success</h3>
                </>
              )}

              <p>{errorMessage}</p>
            </div>
          </div>
        </Modal>
      </CSSTransition>
    </>
  );
};

export default TransferPersonal;
