import { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth'
import Moment from 'moment'
import TransactionDetail from '../Common/TransactionDetail'
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';

const BusinessDashboard = () => {
    Moment.locale('en');
    Modal.setAppElement('body');

    const [isTransactionDetailOpen, setIsTransactionDetailOpen] = useState(false);
    const [transactionData, setTransactionData] = useState(null);

    const toggleTransactionModal = (trnDetail) => {
        setTransactionData(trnDetail);
        setIsTransactionDetailOpen(!isTransactionDetailOpen);
    }

    const [errorIsOpen, setErrorIsOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();
    const [cardList, setCardList] = useState([]);
    const [currency, setCurrency] = useState("UZS");
    const [masterBalance, setMasterBalance] = useState(0.0);
    const [transactionFilter, setTransactionFilter] = useState({ lastTransactionId: 0, transactionType: "", currency: "", pageSize: 10 });
    const [transactionList, setTransactionList] = useState([]);

    const defaultNewCard = {
        card_bin: "",
        card_holder_name: "",
        card_name: "",
        exp_month: "",
        exp_year: ""
    };
    const [newCard, setNewCard] = useState(defaultNewCard);

    const getCardList = async () => {
        const requestOptions = {
            method: 'GET'
        };
        fetch('api/card', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            setCardList(x.data);
                        }
                    });
                }
            })
    }

    const errorModalStyles = {
        overlay: {
            backgroundColor: '#00000070'
        },
    };

    const toggleErrorModal = (msg) => {
        setErrorMessage(msg);
        setErrorIsOpen(!errorIsOpen);
    }

    const getTransactionList = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(transactionFilter)
        };
        fetch('api/transaction', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            setTransactionList(x.data);
                        }
                    });
                }
            })
    }

    const newCreditcardHandleChange = (e) => {
        const value = e.target.value;
        setNewCard({
            ...newCard,
            [e.target.name]: value
        })
    }

    const addCreditCardSubmit = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newCard)
        };
        setLoading(true);
        fetch('api/card', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(x => {
                        if (x.status) {
                            setModalIsError(false);
                            toggleErrorModal("Card successfully added");
                            getCardList();
                        } else {
                            setModalIsError(true);
                            toggleErrorModal(x.message)
                        }
                    });
                } else {
                    toggleErrorModal("Server error");
                }
            })
            .finally(() => {
                setNewCard(defaultNewCard);
                setLoading(false);
            })
    }

    useEffect(() => {
        getCardList();
        getTransactionList();
        setMasterBalance(auth.user.wallets.filter(x => x.currency == currency).reduce((a, v) => a = a + v.cash_available_balance + v.credit_available_balance, 0));
    }, []);

    return (
        <>
            {loading ?
                <div className="preloader"></div> :
                null
            }
            <section className="dashboard-section body-collapse">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-content">
                                    <div className="acc-details">
                                        <div className="top-area">
                                            <div className="left-side">
                                                <h5>Hi, {auth?.user?.account?.company_name}!</h5>
                                                <CurrencyFormat value={masterBalance} decimalScale={2} fixedDecimalScale={true}  displayType={'text'} thousandSeparator={true} prefix={currency} renderText={value => <h2>{value}</h2>} />
                                            </div>
                                            <div className="right-side">
                                                <div className="right-top">
                                                    <select
                                                        value={currency}
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                    >
                                                        <option value="UZS">SUM</option>
                                                    </select>
                                                </div>
                                                <div className="right-bottom">
                                                    <CurrencyFormat value={masterBalance} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={currency} renderText={value => <h4>{value}</h4>} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-area">
                                            <div className="left-side">
                                                <Link to="/business/transfer" className="cmn-btn">Transfer Money</Link>
                                                <Link to="/business/deposit" className="cmn-btn">Add Money</Link>
                                                <Link to="/business/withdraw" className="cmn-btn">Withdraw</Link>
                                            </div>
                                            <div className="right-side">
                                                <div className="dropdown-area">
                                                    <button type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="assets/images/icon/option.png" alt="icon" />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="recipients.html">Recipients</a></li>
                                                        <li><a className="dropdown-item" href="receive-step-1.html">Request Money</a></li>
                                                        <li><a className="dropdown-item" href="pay-step-1.html">Send Money</a></li>
                                                        <li><a className="dropdown-item" href="pay-step-1.html">Bill Pay</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side-items">
                                        <div className="single-item">
                                            <div className="section-text d-flex align-items-center justify-content-between">
                                                <h6>Linked Payment system</h6>
                                            </div>
                                            <div className="row">
                                                {
                                                    cardList ?
                                                        cardList.map((card) => (
                                                            <div className="cc-card-item linked" key={card.card_token}>
                                                                <label className="bankname">{card.card_bank_name}</label>
                                                                <label className="cardname">{card.card_name}</label>
                                                                <label className="cardnumber">{card.card_bin_first_six.substring(0, 4) + ' ' + card.card_bin_first_six.substring(4, 6) + '** **** ' + card.card_bin_last_four}</label>
                                                                <label className="cardexpire">{card.exp_month} / {card.exp_year}</label>
                                                            </div>
                                                        ))
                                                        : null
                                                }
                                                <div className="cc-card-item addnew" data-bs-toggle="modal" data-bs-target="#addcardMod" >
                                                    <label>+</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="section-content">
                                    <div className="transactions-area mt-40">
                                        <div className="section-text d-flex align-items-center justify-content-between mb-0">
                                            <h5>Transactions</h5>
                                            <div className="right-side">
                                                <div className="view-all d-flex align-items-center">
                                                    <Link to="/business/transactions">View All</Link>
                                                    <img src="assets/images/icon/right-arrow.png" alt="icon" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="latest" role="tabpanel" aria-labelledby="latest-tab">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name/ Business</th>
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                transactionList.map((transaction) => (
                                                                    <tr onClick={(() => toggleTransactionModal(transaction))} key={transaction.tx_ref_relation_id} data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                                        <th scope="row">
                                                                            <p>{JSON.parse(transaction.json_data).other_side_title}</p>
                                                                            <p className="mdr">{transaction.tx_type}</p>
                                                                        </th>
                                                                        <td>
                                                                            <p>{Moment(transaction.insert_date).format('HH:mm')}</p>
                                                                            <p className="mdr">{Moment(transaction.insert_date).format('DD MMM yyyy')}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className={
                                                                                transaction.financial_process_status == 1 || transaction.financial_process_status == 11 ?
                                                                                    "trx-pending" :
                                                                                    transaction.financial_process_status == 2 ? "trx-inprogress" :
                                                                                        transaction.financial_process_status == 3 || transaction.financial_process_status == 5 ? "trx-completed" :
                                                                                            transaction.financial_process_status == 4 ? "trx-cancelled" : ""


                                                                            }>
                                                                                {
                                                                                    transaction.financial_process_status == 1 || transaction.financial_process_status == 11 ?
                                                                                        "Pending" :
                                                                                        transaction.financial_process_status == 2 ? "In Progress" :
                                                                                            transaction.financial_process_status == 3 || transaction.financial_process_status == 5 ? "Completed" :
                                                                                                transaction.financial_process_status == 4 ? "Cancelled" : "Not Known"


                                                                                }
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transaction.base_amount - transaction.fee_amount}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                prefix={(transaction.deposit_flag == 1 ? "+" : "-") + (transaction.currency_info ? transaction.currency_info.currency_symbol : transaction.currency)}
                                                                                renderText={value => <p>{value}</p>}
                                                                            />
                                                                            <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={transaction.fee_amount}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                prefix={transaction.currency_info ? transaction.currency_info.currency_symbol : transaction.currency}
                                                                                renderText={value => <p className="mdr">{value}</p>}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="side-items">
                                    <div className="single-item">
                                        <div className="section-text d-flex align-items-center justify-content-between">
                                            <h6>Payment Analytics</h6>
                                            <div className="select-box">
                                                <select>
                                                    <option>Monthly</option>
                                                    <option value={1}>Jan</option>
                                                    <option value={2}>Feb</option>
                                                    <option value={3}>Mar</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div id="chart" />
                                    </div>
                                    {/*<div className="single-item">*/}
                                    {/*    <div className="section-text d-flex align-items-center justify-content-between">*/}
                                    {/*        <h6>Recipients</h6>*/}
                                    {/*        <div className="view-all d-flex align-items-center">*/}
                                    {/*            <a href="#">View All</a>*/}
                                    {/*            <img src="assets/images/icon/right-arrow.png" alt="icon" />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <ul className="recipients-item">*/}
                                    {/*        <li>*/}
                                    {/*            <p className="left d-flex align-items-center">*/}
                                    {/*                <img src="assets/images/recipients-1.png" alt="icon" />*/}
                                    {/*                <span className="info">*/}
                                    {/*                    <span>Phil King</span>*/}
                                    {/*                    <span>08:00 AM — 19 August</span>*/}
                                    {/*                </span>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="right">*/}
                                    {/*                <span> +$345</span>*/}
                                    {/*                <span>Payment</span>*/}
                                    {/*            </p>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <p className="left d-flex align-items-center">*/}
                                    {/*                <img src="assets/images/recipients-2.png" alt="icon" />*/}
                                    {/*                <span className="info">*/}
                                    {/*                    <span>Debra Wilson</span>*/}
                                    {/*                    <span>08:00 AM — 19 August</span>*/}
                                    {/*                </span>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="right">*/}
                                    {/*                <span className="loss">-$850</span>*/}
                                    {/*                <span>Refund</span>*/}
                                    {/*            </p>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <p className="left d-flex align-items-center">*/}
                                    {/*                <img src="assets/images/recipients-3.png" alt="icon" />*/}
                                    {/*                <span className="info">*/}
                                    {/*                    <span>Philip Henry</span>*/}
                                    {/*                    <span>08:00 AM — 19 August</span>*/}
                                    {/*                </span>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="right">*/}
                                    {/*                <span>+$2.050</span>*/}
                                    {/*                <span>Payment</span>*/}
                                    {/*            </p>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <p className="left d-flex align-items-center">*/}
                                    {/*                <img src="assets/images/recipients-4.png" alt="icon" />*/}
                                    {/*                <span className="info">*/}
                                    {/*                    <span>Erin Garcia</span>*/}
                                    {/*                    <span>08:00 AM — 19 August</span>*/}
                                    {/*                </span>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="right">*/}
                                    {/*                <span> +$900</span>*/}
                                    {/*                <span>Payment</span>*/}
                                    {/*            </p>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Dashboard Section end */}
            {/* Card Popup start */}
            <div className="card-popup">
                <div className="container-fruid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="cardMod" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-center">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                                        </div>
                                        <div className="main-content">
                                            <div className="top-area mb-40 mt-40 text-center">
                                                <div className="card-area mb-30">
                                                    <img src="assets/images/visa-card-2.png" alt="image" />
                                                </div>
                                                <div className="text-area">
                                                    <h5>Paylio payment Card </h5>
                                                    <p>Linked: 01 Jun 2021</p>
                                                </div>
                                            </div>
                                            <div className="tab-area d-flex align-items-center justify-content-between">
                                                <ul className="nav nav-tabs mb-30" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="btn-link" id="cancel-tab" data-bs-toggle="tab" data-bs-target="#cancel" type="button" role="tab" aria-controls="cancel" aria-selected="false">
                                                            <img src="assets/images/icon/limit.png" alt="icon" />
                                                            Set transfer limit
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="d-none" id="limit-tab" data-bs-toggle="tab" data-bs-target="#limit" type="button" role="tab" aria-controls="limit" aria-selected="true" />
                                                    </li>
                                                    <li>
                                                        <button>
                                                            <img src="assets/images/icon/remove.png" alt="icon" />
                                                            Remove from Linked
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content mt-30">
                                                <div className="tab-pane fade show active" id="limit" role="tabpanel" aria-labelledby="limit-tab">
                                                    <div className="bottom-area">
                                                        <p className="history">Transaction History : <span>20</span></p>
                                                        <ul>
                                                            <li>
                                                                <p className="left">
                                                                    <span>03:00 PM</span>
                                                                    <span>17 Oct, 2020</span>
                                                                </p>
                                                                <p className="right">
                                                                    <span>$160.48</span>
                                                                    <span>Withdraw</span>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="left">
                                                                    <span>01:09 PM</span>
                                                                    <span>15 Oct, 2021</span>
                                                                </p>
                                                                <p className="right">
                                                                    <span>$106.58</span>
                                                                    <span>Withdraw</span>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="left">
                                                                    <span>04:00 PM</span>
                                                                    <span>12 Oct, 2020</span>
                                                                </p>
                                                                <p className="right">
                                                                    <span>$176.58</span>
                                                                    <span>Withdraw</span>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="left">
                                                                    <span>06:00 PM</span>
                                                                    <span>25 Oct, 2020</span>
                                                                </p>
                                                                <p className="right">
                                                                    <span>$167.85</span>
                                                                    <span>Withdraw</span>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="cancel-tab">
                                                    <div className="main-area">
                                                        <div className="transfer-area">
                                                            <p>Set a transfer limit for paylio payment Card</p>
                                                            <p className="mdr">Transfer Limit</p>
                                                        </div>
                                                        <form action="#">
                                                            <div className="input-area">
                                                                <input className="xxlr" placeholder={400.00} type="number" />
                                                                <select>
                                                                    <option value={1}>USD</option>
                                                                    <option value={2}>USD</option>
                                                                    <option value={3}>USD</option>
                                                                </select>
                                                            </div>
                                                            <div className="bottom-area d-flex align-items-center justify-content-between">
                                                                <a href="#" className="cmn-btn cancel">Cancel and
                                                                    Back</a>
                                                                <a href="#" className="cmn-btn">Confirm Transfer
                                                                    Limit</a>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card Popup start */}
            {/* Add Card Popup start */}
            <div className="add-card">
                <div className="container-fruid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="addcardMod" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-between">
                                            <h6>Add Card</h6>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                                        </div>
                                        <form onSubmit={addCreditCardSubmit}>
                                            <div className="row justify-content-center">
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardNumber">Card Name</label>
                                                        <input type="text"
                                                            name="card_name"
                                                            placeholder="My Card"
                                                            autoComplete="off"
                                                            value={newCard.card_name}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardNumber">Card Number</label>

                                                        <CurrencyFormat type="text"
                                                            name="card_bin"
                                                            placeholder="0000-0000-0000-0000"
                                                            autoComplete="off"
                                                            value={newCard.card_bin}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="#### #### #### ####"
                                                            mask="_"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="single-input">
                                                        <label htmlFor="cardHolder">Card Holder</label>
                                                        <input type="text"
                                                            name="card_holder_name"
                                                            placeholder="Albert Einstein"
                                                            autoComplete="off"
                                                            value={newCard.card_holder_name}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="single-input">
                                                        <label htmlFor="month">Month</label>
                                                        <CurrencyFormat type="text"
                                                            name="exp_month"
                                                            placeholder={12}
                                                            autoComplete="off"
                                                            value={newCard.exp_month}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="##"
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="single-input">
                                                        <label htmlFor="year">Year</label>
                                                        <CurrencyFormat type="text"
                                                            name="exp_year"
                                                            placeholder={30}
                                                            autoComplete="off"
                                                            value={newCard.exp_year}
                                                            onChange={(e) => newCreditcardHandleChange(e)}
                                                            format="##"
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="btn-border w-100">
                                                        <button className="cmn-btn w-100">Add Card</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Card Popup start */}
            {/* Transactions Popup start */}

            <div className="transactions-popup">
                <div className="container-fruid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="transactionsMod" aria-hidden="true">
                                <TransactionDetail isOpen={isTransactionDetailOpen} toggleModal={toggleTransactionModal} closeOnOutsideClick={false} transactionData={transactionData}>

                                </TransactionDetail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Transactions Popup start */}

            <CSSTransition
                in={errorIsOpen}
                timeout={300}
                classNames="dialog"
            >
                <Modal
                    closeTimeoutMS={500}
                    isOpen={errorIsOpen}
                    style={errorModalStyles}
                    className={(modalIsError ? "error-modal" : "success-modal") + " modal-dialog modal-dialog-centered transfer-popup"}
                    onClick={(() => toggleErrorModal(""))}
                >
                    <div className="modal-content">
                        <div className="modal-header mb-60 justify-content-between">
                            <button type="button" onClick={(() => toggleErrorModal(""))} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="main-content text-center">
                            {
                                modalIsError ?
                                    <>
                                        <img src="assets/images/icon/error.png" alt="icon" />
                                        <h3>Error</h3>
                                    </>
                                    :
                                    <>
                                        <img src="assets/images/icon/success.png" alt="icon" />
                                        <h3>Success</h3>
                                    </>
                            }

                            <p>{errorMessage}</p>
                        </div>
                    </div>
                </Modal>
            </CSSTransition>
        </>
    )
}

export default BusinessDashboard;