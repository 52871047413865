import { useNavigate } from "react-router-dom";
const { fetch: originalFetch } = window;

const HttpInterceptor = () => {

    const navigate = useNavigate();

    window.fetch = async (...args) => {
        let [resource, config] = args;
        let response = await originalFetch(resource, config);
        if (!response.ok && response.status === 401) {
            navigate("/auth/login");
        } else if (!response.ok && response.status === 403) {
            navigate("/auth/checkuser");
        }
        return response;
    };
}

export default HttpInterceptor;